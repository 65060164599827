import React from 'react';
import { Link } from "@amzn/awsui-components-react/polaris";

const PhoneToolLink = ({login}) => {
    return (
        <Link
            target="_blank"
            href={`https://phonetool.amazon.com/users/${login}`}
        >
            {login}@
        </Link>
    )
}

export default PhoneToolLink