import React, { useEffect, useState, useContext } from 'react';
import AppNavigation from "src/components/AppNavigation";
import AppBreadcrumbs from "src/components/AppBreadcrumbs";
import {
    Alert,
    AppLayout,
    Container,
    DateRangePicker,
    DateRangePickerProps,
    Form,
    FormField,
    Header,
    Multiselect,
    MultiselectProps,
    RadioGroup,
    Select,
    SelectProps,
    SpaceBetween,
    Textarea,
} from "@amzn/awsui-components-react/polaris";
import { SurveyFormActions } from "src/components/survey-builder/SurveyFormActions";
import OptionsEditor from "src/components/survey-builder/SurveyOptions";
import { SurveyService } from "src/components/survey-builder/Survey.service";
import { Loading } from "src/components/Loading";
import { useParams } from "react-router-dom";
import {
    SurveyOption,
    SurveyQuestion,
    SurveyQuestionStatusType
} from "src/components/survey-builder/SurveyQuestionTypes";
import { getCurrentMoment, getMomentFromDateTimeString } from "src/components/DateFormatter";
import { UserContext } from "src/components/context/UserContext";
import { TENANT_OPTIONS } from "src/components/common/TenantSelector";
import { TenantId } from "src/@types/tenants";
import { NotificationsContext } from 'src/components/context/NotificationsContext';

const Content = (props) => {
    const { selectedTenant } = useContext(UserContext);

    const [surveyQuestion, setSurveyQuestion] = useState<SurveyQuestion | null>(null);

    const [tenant, setTenant] = useState(selectedTenant.id);

    const [question, setQuestion] = useState("");

    const [options, setOptions] = useState<SurveyOption[]>([]);

    const [dateRange, setDateRange] = useState<DateRangePickerProps.AbsoluteValue | null>(null);

    const [status, setStatus] = useState<SelectProps.Option>({label: "Active", value: SurveyQuestionStatusType.Active});

    const [tags, setTags] = useState<MultiselectProps.Options>( []);

    const [error, setError] = useState("");

    const [loading, setLoading] = useState(true);

    const [tagOptions, setTagOptions] = useState<MultiselectProps.Options>( []);

    const initDateRange = (question) => {
        return {
            startDate: question.start_date_time,
            endDate: question.end_date_time,
            type: 'absolute' as 'absolute'
        }
    };

    const initStatus = (question) => {
        return {
            label: question.survey_status === SurveyQuestionStatusType.Active ? "Active" : "Inactive",
            value: question.survey_status
        }
    };

    useEffect(() => {
        document.title = 'Axiom Admin - Survey Form';
        if (!props.newQuestion) {
            SurveyService.getQuestionById(props.tenantId, props.surveyId)
                .then((question) => {
                    setTenant(question.tenant_id);
                    setQuestion(question.question);
                    setOptions(question.options);
                    setDateRange(initDateRange(question));
                    setStatus(initStatus(question));
                    setTags(question.tags);
                    setSurveyQuestion(question);
                })
                .catch(
                    (error) => setError(error.toString())
                )
                .finally(
                    () => setLoading(false)
                );
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
            SurveyService.getTags(tenant)
                .then((tags) => {
                    setTagOptions(tags);
                })
                .catch(
                    (error) => setError(error.toString())
                );
    }, [tenant]);

    const onSave = async () => {
        try {
            return props.newQuestion ?
                await SurveyService.createQuestion(tenant, status, dateRange, question, options, tags)
                :
                await SurveyService.updateQuestion(tenant, status, dateRange, question, options, tags, surveyQuestion!)
        } catch (err) {
            // @ts-ignore
            setError(err.toString());
        }
    };

    const updateOptions = (items) => {
        return setOptions(items);
    };


    const validateDateRange = (value: DateRangePickerProps.Value | null): DateRangePickerProps.ValidationResult => {
        const absoluteValue = value as DateRangePickerProps.AbsoluteValue;
        if (getMomentFromDateTimeString(absoluteValue.startDate).startOf('day') < getCurrentMoment().startOf('day')) {
            return {
                valid: false,
                errorMessage: "Minimum accepted start date is today"
            };
        }
        return getMomentFromDateTimeString(absoluteValue.startDate) < getMomentFromDateTimeString(absoluteValue.endDate) ?
            {
                valid: true
            } :
            {
                valid: false,
                errorMessage: "End date must be greater than start date"
            };
    };

    if (loading) {
        return (
            <Loading />
        )
    } else {
        return (
            <React.Fragment>
                {
                    error &&
                    <Alert type="error" dismissible onDismiss={() => setError('')}>
                        {error}
                    </Alert>
                }
                <form onSubmit={e => e.preventDefault()}>
                    <Form
                        actions={<SurveyFormActions onSave={onSave}
                                                    isNewQuestion={props.newQuestion}
                                                    tenantId={surveyQuestion?.tenant_id}
                                                    surveyId={surveyQuestion?.survey_id}
                                                    startDate={dateRange?.startDate}
                                                    surveyStatus={surveyQuestion?.survey_status}
                        />}
                        header={
                            <Header
                                variant="h1"
                                description="Fill the form to create a new survey question"
                            >
                                Create survey question
                            </Header>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <Container
                                header={
                                    <Header variant="h2">
                                        Question information
                                    </Header>
                                }
                            >
                                <SpaceBetween direction="vertical" size="l">
                                    <FormField label="Select tenant">
                                        <RadioGroup
                                            onChange={({detail}) => setTenant(detail.value as TenantId)}
                                            value={tenant}
                                            // TODO: extract tenant values to a configuration file or from tenant config
                                            items={TENANT_OPTIONS.map(option => ({...option, disabled: true}))}
                                        />
                                    </FormField>
                                    <FormField label="Select date range">
                                        <DateRangePicker
                                            // @ts-ignore
                                            onChange={({detail}) => setDateRange(detail.value)}
                                            value={dateRange}
                                            i18nStrings={{
                                                todayAriaLabel: "Today",
                                                nextMonthAriaLabel: "Next month",
                                                previousMonthAriaLabel: "Previous month",
                                                customRelativeRangeDurationLabel: "",
                                                customRelativeRangeDurationPlaceholder: "",
                                                customRelativeRangeOptionLabel: "",
                                                customRelativeRangeOptionDescription: "",
                                                customRelativeRangeUnitLabel: "",
                                                formatRelativeRange: e => {
                                                    const t =
                                                        1 === e.amount ? e.unit : `${e.unit}s`;
                                                    return `Last ${e.amount} ${t}`;
                                                },
                                                formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                                                dateTimeConstraintText:
                                                    "Range must be between 1 and 365 days. Use 24 hour format.",
                                                relativeModeTitle: "",
                                                absoluteModeTitle: "Absolute range",
                                                relativeRangeSelectionHeading: "",
                                                startDateLabel: "Start date",
                                                endDateLabel: "End date",
                                                startTimeLabel: "Start time",
                                                endTimeLabel: "End time",
                                                clearButtonLabel: "Clear and dismiss",
                                                cancelButtonLabel: "Cancel",
                                                applyButtonLabel: "Apply"
                                            }}
                                            placeholder="Specify date range for the question"
                                            rangeSelectorMode="absolute-only"
                                            relativeOptions={[]}
                                            isValidRange={(value) => validateDateRange(value)}
                                        />
                                    </FormField>
                                    <FormField label="Select status">
                                        <Select
                                            selectedOption={status}
                                            onChange={({detail}) =>
                                                setStatus(detail.selectedOption)}
                                            options={[
                                                {label: "Active", value: SurveyQuestionStatusType.Active},
                                                {label: "Inactive", value: SurveyQuestionStatusType.Inactive}
                                            ]}
                                            selectedAriaLabel="Selected"
                                        />
                                    </FormField>
                                    <FormField label="Select tags">
                                        <Multiselect
                                            selectedOptions={tags}
                                            onChange={({detail}) =>
                                                setTags(detail.selectedOptions)
                                            }

                                            deselectAriaLabel={e => `Remove ${e.label}`}
                                            options={tagOptions}
                                            placeholder="Choose audience tags"
                                            selectedAriaLabel="Selected"
                                            filteringType="auto"
                                        />
                                    </FormField>
                                </SpaceBetween>
                            </Container>
                            <Container
                                header={
                                    <Header variant="h2">
                                        Question content
                                    </Header>
                                }
                            >
                                <SpaceBetween direction="vertical" size="s">
                                    <FormField label="Question"
                                               description="Add a question">
                                        <Textarea
                                            onChange={({detail}) => setQuestion(detail.value)}
                                            value={question}
                                            placeholder="Add survey question here"
                                        />
                                    </FormField>
                                    <div>
                                        <Header variant="awsui-h1-sticky">
                                            Options
                                        </Header>
                                        <OptionsEditor items={options} setItems={updateOptions}/>
                                    </div>
                                </SpaceBetween>
                            </Container>
                        </SpaceBetween>
                    </Form>
                </form>
            </React.Fragment>
        );
    }
};

export const SurveyForm = (props) => {
    const { tenantId, surveyId } = useParams();
    const { selectedTenant, setSelectedTenant } = useContext(UserContext);
    const { conditionallyDisplayTenantChangeAlert } = useContext(NotificationsContext);

    conditionallyDisplayTenantChangeAlert('survey', selectedTenant, tenantId, setSelectedTenant);

    const isEdit = tenantId && surveyId;

    const breadcrumbs = isEdit ?
        [
            {text: 'Axiom Admin', href: '/'},
            {text: 'Survey Builder', href: '/survey-builder'},
            {text: 'Survey Details', href:  `/survey-builder/${tenantId}/${surveyId}`},
            {text: 'Survey Form', href: '#/'}
        ] :
        [
            {text: 'Axiom Admin', href: '/'},
            {text: 'Survey Builder', href: '/survey-builder'},
            {text: 'Survey Form', href: '#/'}
        ]

    return (
        <AppLayout
            breadcrumbs={<AppBreadcrumbs items={breadcrumbs}/>}
            contentType="form"
            content={<Content newQuestion={props.newQuestion} tenantId={tenantId} surveyId={surveyId} />}
            navigation={<AppNavigation/>}
        />
    );
};