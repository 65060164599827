/**
 * Add BucketsConfig to store buckets config in a new object to
 * replace the original way to store buckets config with json
 * files under public folder which caused a shepherd risk
 * https://shepherd.a2z.com/issues/de5197bc-0298-4763-a9d7-766ca97ff997
 */
interface BucketsConfig {
    BETA_BUCKET_REGION: string,
    BETA_BUCKET: string,
    PROD_BUCKET_REGION: string,
    PROD_BUCKET: string
}
export const BUCKETS_CONFIG: BucketsConfig = {
    BETA_BUCKET_REGION: "us-west-2",
    BETA_BUCKET: "wfmaxiomglobalstack-beta-axiomnarrativetemplatesb-1c1vzl1huqu24",
    PROD_BUCKET_REGION: "us-east-1",
    PROD_BUCKET: "wfmaxiomglobalstack-prod-axiomnarrativetemplatesb-vuaqor060emv"
}

