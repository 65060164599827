import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import AppNavigation from "src/components/AppNavigation";
import { FormDisplay } from "src/components/forms/FormDisplay";
import {
    FormDefinition,
    FormDefinitionResponse,
    FormDefinitionStatus,
    FormTemplateType
} from "src/components/forms/service/amplify/appsync";
import { FormsService } from "src/components/forms/service/Forms.service";
import { withKatalAssets } from "src/components/wrappers/withKatalAssets";
import './Forms.scss';
import { CodeSnippet } from "src/components/common/code-editor/CodeSnippet";
import {
    AppLayout,
    ColumnLayout,
    Container,
    ExpandableSection,
    Form,
    FormField,
    Header,
    Input,
    RadioGroup,
    Select,
    SelectProps,
    SpaceBetween,
    Button,
    Toggle
} from "@amzn/awsui-components-react/polaris";
import AppBreadcrumbs from "src/components/AppBreadcrumbs";
import { Loading } from "src/components/Loading";
import { FormComposerActions } from "src/components/forms/FormComposerActions";
import { EXAMPLE_FORM_TEMPLATE } from "src/components/forms/FormConstants";
import { Alert } from "@amzn/awsui-components-react";
import BindleLink from '../common/BindleLink';
import TimeToEditFormSubmissionsInput from 'src/components/forms/TimeToEditFormSubmissionsInput';
import { isPositiveIntegerNumber } from 'src/components/forms/TimeToEditFormSubmissionsInput';
import locales from "src/i18n/locales"
import { UserContext } from "src/components/context/UserContext";
import { TENANT_OPTIONS } from "src/components/common/TenantSelector";
import { NotificationsContext } from 'src/components/context/NotificationsContext';

const COMPONENT_NAME = 'Form Designer - Compose';
const DEFAULT_LANGUAGE = locales[0];

const FORM_NAME_REGEX = new RegExp('^[a-z]+[a-z0-9]*(-[a-z0-9]+)*$');

const Content = (props) => {

    const { newForm, tenantId, language, formName, formDefinitionId } = props;
    const { selectedTenant } = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [selectedLanguage, setSelectedLanguage] = useState<SelectProps.Option>(DEFAULT_LANGUAGE);

    const [extensions, setExtensions] = useState(JSON.stringify([], null, 2));

    const [formTemplate, setFormTemplate] = useState<string>(newForm ? JSON.stringify(EXAMPLE_FORM_TEMPLATE, null, 2) : "");

    const [formDefinition, setFormDefinition] = useState<FormDefinition>({
        __typename: "FormDefinition",
        id: "",
        application: "Axiom",
        tenantId: selectedTenant.id,
        language: DEFAULT_LANGUAGE.value,
        formName: "",
        displayTitle: "",
        description: "",
        version: "1.0",
        versionComment: "",
        status: FormDefinitionStatus.Active,
        formTemplateType: FormTemplateType.AxiomForm,
        formTemplate: "",
        createdDateTime: "",
        createdBy: "",
        helpContentLink: "",
        timeToEditFormSubmissions: 0,
        bindle: "",
        bindleResourceId: "",
        allowDrafts: false
    });

    const [isMajorVersion, setIsMajorVersion] = useState<string>("0");

    const [errorMessage, setErrorMessage] = useState<(string)>("");

    const [errors, setErrors] = useState<{ [field: string]: string }>({
        formName: '',
        displayTitle: ''
    });

    const [canSave, setCanSave] = useState<boolean>(false);
    const [userCannotEdit, setUserCannotEdit] = useState(false)

    useEffect(() => {
        document.title = `${COMPONENT_NAME}`;
        if (!newForm) {
            getForm();
        }
    }, []);

    useEffect(() => {
        updateFormDefinitionField('language', selectedLanguage.value || DEFAULT_LANGUAGE.value)
    }, [selectedLanguage]);

    useEffect(() => {
        if (newForm) {
            updateFormDefinitionField('tenantId', selectedTenant.id)
        }
    }, [selectedTenant]);

    useEffect(() => {
        Object.values(errors).some(errorMessage => !!errorMessage) ?
            setCanSave(false) : setCanSave(true);
    }, [errors]);

    useEffect(() => {
        validateForm();
    }, [formDefinition, formTemplate, extensions]);

    const getForm = async () => {
        setLoading(true);
        setErrorMessage("");

        const promise = formDefinitionId
            ?   FormsService.getFormDefinitionById(formDefinitionId)
            :   FormsService.getLatestFormDefinition(tenantId, language, formName);
        
        promise
        .then(handleGetFormResponses)
        .catch(handleError);
    };

    const submitForm = async () => {
        // Do nothing for now
    };

    const handleGetFormResponses = (response: FormDefinitionResponse) => {
        const formDefinition = response?.body?.formDefinition
        if (FormsService.isSuccessful(response) && formDefinition) {
            const editableByUser = response?.body?.isEditableByUser
            if (!editableByUser) {
                setUserCannotEdit(true)
            } else {
                try {
                    formDefinition.versionComment = "";
                    setFormDefinition(formDefinition);
                    const extensionsArray = formDefinition.extensions?.map((extension) => {
                        return {
                            id: extension!.id,
                            name: extension?.name,
                            type: extension?.type,
                            content: extension?.content
                        }
                    }) || [];
                    setExtensions(JSON.stringify(extensionsArray, null, 2));
    
                    const parsedTemplate = JSON.parse(formDefinition.formTemplate);
                    setFormTemplate(JSON.stringify(parsedTemplate, null, 2));
                    setErrorMessage("");
                    setLoading(false);
                } catch (error) {
                    handleError("Error parsing existing JSON for form template");
                }
            }
        } else {
            handleError(`Form with name ${formName} not found`);
        }
    };

    const handleError = (responseError: any) => {
        setLoading(false);
        setErrorMessage(typeof responseError === 'string' ? responseError : JSON.stringify(responseError));
        // tslint:disable-next-line:no-console
        console.error(errorMessage);
    };

    const scrollSmoothlyToTop = () => window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });

    const onSave = async () => {
        try {
            if (canSave) {
                return await FormsService.createFormDefinition(formDefinition, formTemplate, JSON.parse(extensions), isMajorVersion);
            }
        } catch (responseError: any) {
            console.log('Error on save form definition', responseError);
            const message = typeof responseError === 'string'
                ? responseError
                : responseError.data.createFormDefinition.message || JSON.stringify(responseError);
            setErrorMessage(message);
            scrollSmoothlyToTop();
        }
    };

    const validateForm = () => {
        const bindleError = !formDefinition.bindle
            ? 'Bindle name is required'
            : /\s/g.test(formDefinition.bindle)
                ? 'Bindle name cannot contain whitespaces'
                : '';

        const timeToEditFormSubmissionsError = !isPositiveIntegerNumber(formDefinition.timeToEditFormSubmissions)
                ? 'Time to edit must be a positive integer number'
                : ''

        const errorsObject = {
            formName: !formDefinition.formName || !FORM_NAME_REGEX.test(formDefinition.formName)
                ? 'Form name must use only lower case letters, numbers and dashes (-), and start with a letter'
                : '',
            displayTitle: !formDefinition.displayTitle ? 'Display title is required.' : '',
            bindle: bindleError,
            formTemplate: !isValidJson(formTemplate) ? 'Form template is not a valid json' : '',
            extensions: !isValidJson(extensions) ? 'Extensions is not a valid json' : '',
            timeToEditFormSubmissions: timeToEditFormSubmissionsError,
            versionComment: !formDefinition.versionComment ? 'Version comment is required' : ''
        };

        setErrors(errorsObject);
    };

    const isValidJson = (jsonString: string) => {
        try {
            JSON.parse(jsonString);
            return true;
        } catch {
            return false;
        }
    }

    const updateFormDefinitionField = <F extends keyof FormDefinition>(field: F, value: any) => {
        const updatedFormDefinition = { ...formDefinition };
        updatedFormDefinition[field] = value;
        setFormDefinition(updatedFormDefinition);
    }

    const ErrorBanner = (message: string | undefined) => (
        <>{message &&
            <Alert
                dismissAriaLabel="Close"
                dismissible
                header="Error creating form definition"
                type="error"
            >
                {message}
            </Alert>
        }</>
    );

    const FormDesignerHeader = () => (
        <>{newForm ? ( <Header
            variant="h1"
            description="Fill the form to create a new form definition"
        >
            Create form definition
        </Header>
        )
        : ( <Header
            variant="h1"
            description="Fill the form to edit the form definition"
        >
            Edit {formDefinition.formName}
        </Header>
        )}</>
    );

    if (userCannotEdit) {
        return ErrorBanner("You're not authorized to edit this form definition")
    }

    if (loading) {
        return (
            <Loading/>
        )
    } else {
        const templateTypeOptions = Object.values(FormTemplateType).map(value => {
            return { label: value, value: value}
        })
        
        return (
            <React.Fragment>
                {ErrorBanner(errorMessage)}
                <form onSubmit={e => e.preventDefault()}>
                    <Form
                        actions={<FormComposerActions onSave={onSave}
                                                      isNewForm={newForm}
                                                      tenantId={formDefinition.tenantId}
                                                      language={language}
                                                      formName={formDefinition?.formName}
                                                      canSave={canSave}
                        />}
                        header={
                            <FormDesignerHeader/>
                        }
                    >
                        <SpaceBetween size="s">
                            {formDefinitionId &&
                            <Alert statusIconAriaLabel="Error"
                                type="error"
                                header="Inactive Form Version"
                                action={
                                    <Button
                                        ariaLabel="View Active Version"
                                        href={`#/form-designer/${formDefinition.tenantId}/${language}/${formName}`}
                                        iconAlign="right"
                                    >
                                        View Active Version
                                    </Button>
                                }
                            >
                                Making edits from this page will revert changes from more recent form versions.
                            </Alert>}
                            <Container header={
                                <Header variant='h1'
                                        description={'Enter the details of the new form metadata'}
                                >
                                    Form Metadata
                                </Header>}>
                             
                                    <div>
                                        <SpaceBetween direction="vertical" size="m">
                                            <FormField label="Application">
                                                <Input value={formDefinition.application}
                                                       onChange={event => updateFormDefinitionField('application', event.detail.value)}
                                                       disabled
                                                />
                                            </FormField>
                                            <FormField label="Tenant Id">
                                                <RadioGroup value={formDefinition.tenantId}
                                                            onChange={event => updateFormDefinitionField('tenantId', event.detail.value)}
                                                            items={TENANT_OPTIONS.map(option => ({...option, disabled: true}))}
                                                />
                                            </FormField>
                                            <FormField label="Language">
                                                <Select selectedOption={selectedLanguage}
                                                        onChange={({ detail }) => setSelectedLanguage(detail.selectedOption)}
                                                        options={locales}
                                                        selectedAriaLabel="Selected"
                                                        disabled={!newForm}
                                                />
                                            </FormField>
                                            <FormField label="Form name"
                                                       errorText={errors.formName}>
                                                <Input value={formDefinition.formName}
                                                       onChange={event => updateFormDefinitionField('formName', event.detail.value)}
                                                       disabled={!newForm}
                                                />
                                            </FormField>
                                            <FormField label="Display title"
                                                       errorText={errors.displayTitle}>
                                                <Input value={formDefinition.displayTitle}
                                                       onChange={event => updateFormDefinitionField('displayTitle', event.detail.value)}
                                                />
                                            </FormField>
                                            <FormField label={<span>Description <i>- optional</i></span>}>
                                                <Input value={formDefinition.description || ""}
                                                       onChange={event => updateFormDefinitionField('description', event.detail.value)}
                                                />
                                            </FormField>
                                            <FormField label="Form template type">
                                                <Select selectedAriaLabel="Selected"
                                                        selectedOption={{
                                                            label: formDefinition.formTemplateType,
                                                            value: formDefinition.formTemplateType
                                                        }}
                                                        onChange={({ detail }) =>
                                                            updateFormDefinitionField('formTemplateType',
                                                                FormTemplateType[detail.selectedOption.value || FormTemplateType.AxiomForm])
                                                        }
                                                        options={templateTypeOptions}
                                                />
                                            </FormField>
                                            <FormField label="Status">
                                                <Select selectedAriaLabel="Selected"
                                                        selectedOption={{
                                                            label: formDefinition.status,
                                                            value: formDefinition.status
                                                        }}
                                                        onChange={({ detail }) =>
                                                            updateFormDefinitionField('status',
                                                                FormDefinitionStatus[detail.selectedOption.value || FormDefinitionStatus.Active])
                                                        }
                                                        options={[
                                                            { label: FormDefinitionStatus.Active, value: FormDefinitionStatus.Active },
                                                            { label: FormDefinitionStatus.Inactive, value: FormDefinitionStatus.Inactive }
                                                        ]}
                                                />
                                            </FormField>
                                            <FormField label={
                                                <span>Help content link <i>- optional</i></span>}>
                                                <Input value={formDefinition.helpContentLink || ''}
                                                       onChange={event => updateFormDefinitionField('helpContentLink', event.detail.value || '')}
                                                />
                                            </FormField>
                                            <TimeToEditFormSubmissionsInput updateFormDefinitionField={updateFormDefinitionField}
                                                timeToEditFormSubmissions={formDefinition.timeToEditFormSubmissions}
                                                errorText={errors.timeToEditFormSubmissions}
                                            />
                                            <FormField label="Are form submissions drafts allowed?">
                                            <Toggle checked={formDefinition.allowDrafts}
                                                onChange={({ detail }) => updateFormDefinitionField("allowDrafts", detail.checked)}
                                            >
                                                {formDefinition.allowDrafts ? "Yes" : "No"}
                                            </Toggle>
            </FormField>
                                            {newForm &&
                                                <FormField label='Permissions Bindle Name'
                                                           description='This is the bindle where a resource representing this form will be created to manage permissions'
                                                           errorText={errors.bindle}>
                                                    <Input value={formDefinition.bindle}
                                                           onChange={event => updateFormDefinitionField('bindle', event.detail.value)}
                                                    />
                                                </FormField>
                                            }
                                            {!newForm &&
                                                <FormField label='Bindle resource id'>
                                                    <BindleLink bindleResourceId={formDefinition.bindleResourceId} />
                                                </FormField>
                                            }
                                            {newForm && <FormField label="Version">
                                                <Input value={formDefinition.version}
                                                       disabled/>
                                            </FormField>
                                            }
                                            {!newForm && <FormField label="Version increment">
                                                <RadioGroup
                                                    onChange={event => setIsMajorVersion(event.detail.value)}
                                                    value={isMajorVersion}
                                                    items={[
                                                        { value: "0", label: "Minor Version" },
                                                        { value: "1", label: "Major Version" },
                                                    ]}
                                                />
                                            </FormField>
                                            }
                                            <FormField label={
                                                <span>Version comment</span>}
                                                       description="What's new in this version?">
                                                <Input
                                                    value={formDefinition.versionComment || ''}
                                                    onChange={event => updateFormDefinitionField('versionComment', event.detail.value || '')}
                                                />
                                            </FormField>
                                        </SpaceBetween>
                                    </div>
                            </Container>
                            <ExpandableSection
                                    headingTagOverride="h1"
                                    variant="container"
                                    headerText="Extensions"  
                                >
                                <div>
                                    <CodeSnippet
                                        data={extensions}
                                        onChange={setExtensions}
                                        language='json'
                                        editorContentHeightRem={50}
                                        propLoading={loading}/>
                                </div>
                            </ExpandableSection>
                            <Container header={
                                <Header variant='h1' description='Form template editor and preview'>
                                    Form template
                                </Header>
                            }>
                                <ColumnLayout columns={2}>
                                    <div>
                                        <CodeSnippet data={formTemplate}
                                                     onChange={(string) => {
                                                         setFormTemplate(string);
                                                     }}
                                                     language='json'
                                                     editorContentHeightRem={50}
                                                     propLoading={loading}/>
                                    </div>
                                    <div>
                                        <FormDisplay formTemplate={formTemplate}
                                                     displayTitle={formDefinition.displayTitle}
                                                     onSubmit={submitForm}/>
                                    </div>
                                </ColumnLayout>
                            </Container>
                        </SpaceBetween>
                    </Form>
                </form>
            </React.Fragment>
        );
    }
};

export const FormComposer = (props) => {

    const { tenantId, language, formDefinitionName, formDefinitionId } = useParams();
    const { selectedTenant, setSelectedTenant } = useContext(UserContext);
    const { conditionallyDisplayTenantChangeAlert } = useContext(NotificationsContext);

    conditionallyDisplayTenantChangeAlert('form', selectedTenant, tenantId, setSelectedTenant);

    const breadcrumbs = formDefinitionName ?
        [
            { text: 'Axiom Admin', href: '/' },
            { text: 'Form Designer', href: '/form-designer' },
            { text: `${formDefinitionName}`, href: `/form-designer/${tenantId}/${language}/${formDefinitionName}` },
            { text: 'Edit', href: '#/' }
        ] :
        [
            { text: 'Axiom Admin', href: '/' },
            { text: 'Form Designer', href: '/form-designer' },
            { text: 'Create form definition', href: '#/' }
        ];
    return (
        <AppLayout breadcrumbs={<AppBreadcrumbs items={breadcrumbs}/>}
                   contentType="default"
                   navigation={<AppNavigation/>}
                   content={withKatalAssets(<Content newForm={props.newForm}
                                                     tenantId={tenantId}
                                                     language={language}
                                                     formName={formDefinitionName}
                                                     formDefinitionId={formDefinitionId}/>)}
        />
    );
};
