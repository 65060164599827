import React, { useMemo } from 'react';
import { AttributeEditor, Input, Link } from '@amzn/awsui-components-react/polaris';

const i18nStrings = {
    addButtonText: 'Add new option',
    removeButtonText: 'Remove',
    empty: 'No options associated to the question',
};
const optionsLimit = 8;

const Control = ({ value, index, placeholder, setItems, prop }) => {
    return (
        <Input
            value={value}
            placeholder={placeholder}
            onChange={({ detail }) => {
                setItems(items => {
                    const updatedItems = [...items];
                    updatedItems[index] = {
                        ...updatedItems[index],
                        [prop]: detail.value,
                    };
                    return updatedItems;
                });
            }}
        />
    );
};


interface IProps {
    items: any[];
    setItems: any;
}

export default function OptionsEditor(props: IProps) {


    const definition = [
            {
                label: 'Key label',
                control: ({ key = '' }, itemIndex) => (
                    <Control prop="key" value={key} index={itemIndex} placeholder="Enter key" setItems={props.setItems} />
                ),
                errorText: item => (item.key && item.key.length > 25 ? 'Key is longer than 25 characters"' : null),
            },
            {
                label: 'Value label',
                control: ({ value = '' }, itemIndex) => (
                    <Control prop="value" value={value} index={itemIndex} placeholder="Enter value" setItems={props.setItems} />
                ),
                errorText: item =>
                    item.value && item.value.length > 200 ? 'Value is longer than 200 characters"' : null,
            },
        ];


    const onAddButtonClick = () => {
        props.setItems(items => [...items, {}]);
    };

    const onRemoveButtonClick = ({ detail: { itemIndex } }) => {
        props.setItems(items => {
            const newItems = items.slice();
            newItems.splice(itemIndex, 1);
            return newItems;
        });
    };

    const additionalInfo = useMemo(() => `You can add ${optionsLimit - props.items.length} more options.`, [props.items.length]);

    return (
        <AttributeEditor
            {...i18nStrings}
            additionalInfo={additionalInfo}
            items={props.items}
            definition={definition}
            onAddButtonClick={onAddButtonClick}
            onRemoveButtonClick={onRemoveButtonClick}
        />
    );
}
