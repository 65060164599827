import React, { useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Box,
    CollectionPreferences,
    Pagination,
    Table,
    TextFilter
} from "@amzn/awsui-components-react";
import {
    Button,
    Header,
    Select,
    SpaceBetween,
    SelectProps
} from "@amzn/awsui-components-react/polaris";
import { useNavigate } from "react-router-dom";
import locales from "src/i18n/locales";

interface IProps {
    columnDefinitions: any[];
    items: any[] | undefined;
    loading: boolean;
    loadingText: string;
    language: SelectProps.Option;
    setLanguage: Function;
}

const PAGE_SIZE_OPTIONS = [
    { value: 10, label: "10 forms" },
    { value: 20, label: "20 forms" },
    { value: 30, label: "30 forms" }
];

const Preferences = ({ preferences, setPreferences }) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        pageSizePreference={{
            title: "Page size",
            options: PAGE_SIZE_OPTIONS
        }}
    />
);

const TableHeader = (props) => {
    return (
        <Header variant={props.variant} actions={props.actionButtons}>
            {props.title}
        </Header>
    );
};

const FullPageHeader = ({ resourceName = "Form Designer", ...props }) => {
    let navigate = useNavigate();
    const onCreateQuestion = async () => {
        navigate("/form-designer/create");
    };

    return (
        <div>
            <SpaceBetween size="l" direction="vertical">
                <TableHeader
                    variant="awsui-h1-sticky"
                    title={resourceName}
                    actionButtons={
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button variant="primary" data-testid="create-form-button" onClick={onCreateQuestion}>
                                Create Form
                            </Button>
                        </SpaceBetween>
                    }
                    {...props}
                />
            </SpaceBetween>
        </div>
    );
};

function EmptyState({ title, subtitle }) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: "s" }} color="inherit">
                {subtitle}
            </Box>
        </Box>
    );
}

export default function ResourcesTable(props: IProps) {
    const { language, setLanguage } = props;
    
    const [preferences, setPreferences] = useState({
        pageSize: 20,
        visibleContent: ["id", "form_name", "display_title", "version", "created_by", "created_date", "status"],
        wrapLines: false
    });

    const { items, collectionProps, filterProps, paginationProps } = useCollection(props.items!, {
        filtering: {
            empty: <EmptyState title="No forms" subtitle="No forms to display" />,
            noMatch: <EmptyState title="No matches" subtitle="Can't find a match" />
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: props.columnDefinitions[0]
            },
        }
    });
    
    return (
        <Table
            {...collectionProps}
            header={
                <FullPageHeader
                    totalItems={items}
                >
                    Survey Builder
                </FullPageHeader>
            }
            loading={props.loading}
            loadingText={props.loadingText}
            columnDefinitions={props.columnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <SpaceBetween size="s" direction="horizontal">
                    <TextFilter {...filterProps} filteringPlaceholder="Find forms" />
                    <Select
                        selectedOption={language}
                        onChange={({ detail }) =>
                            setLanguage(detail.selectedOption)
                        }
                        options={locales}
                        selectedAriaLabel="Selected"
                    />
                </SpaceBetween>
            }
            preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
        />
    );
}
