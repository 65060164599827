const TENANT_KEY = "selectedTenant";

enum TENANT_IDS {
    TEST_TENANT_ID = "0",
    SPS_TENANT_ID = "1"
}

enum TENANT_STRINGS {
    TEST_TENANT_STRING = "Test Tenant",
    SPS_TENANT_STRING = "SPS Tenant"
}

const DEFAULT_TENANT_ID = TENANT_IDS.TEST_TENANT_ID;

type TenantId = TENANT_IDS.TEST_TENANT_ID | TENANT_IDS.SPS_TENANT_ID;
type TenantString = TENANT_STRINGS.TEST_TENANT_STRING | TENANT_STRINGS.SPS_TENANT_STRING;
interface TenantOption {
    value: TenantId;
    label: TenantString;
}

export {
    TENANT_KEY,
    DEFAULT_TENANT_ID,
    TENANT_IDS,
    TENANT_STRINGS,
    type TenantId,
    type TenantString,
    type TenantOption
};
