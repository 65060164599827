import React, { useContext } from "react";
import { Select, SelectProps } from "@amzn/awsui-components-react";
import { UserContext } from "src/components/context/UserContext";
import { TenantId, TenantOption } from "src/@types/tenants";
import { Tenants } from "src/utils/Tenant";

export const TENANT_OPTIONS: TenantOption[] = Tenants.list().map(tenant => {
    return tenant.buildOption();
});

export default function TenantSelector() {
    const { selectedTenant, setSelectedTenant } = useContext(UserContext);

    const tenantOption = TENANT_OPTIONS.find(
        (option) => option.value === selectedTenant.id
    );

    const handleSelectedTenantChange = (tenantId: TenantId) => {
        setSelectedTenant(Tenants.getTenantById(tenantId));
    };

    return (
        <Select
            selectedOption={tenantOption as SelectProps.Option}
            onChange={({ detail }) =>
                handleSelectedTenantChange(detail.selectedOption.value as TenantId)
            }
            options={TENANT_OPTIONS}
            selectedAriaLabel="Selected"
        />
    );
}
