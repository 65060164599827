import React from "react";
import { Link } from "@amzn/awsui-components-react";
import { STAGE } from "src/aws-config";

export const buildBindleUrl = (stage: string, bindleResourceId: string) => {
    switch(stage) {
        case 'beta':
        case 'gamma':
            return `https://bindles-gamma.amazon.com/resource/${bindleResourceId}`;
        case 'prod':
            return `https://bindles.amazon.com/resource/${bindleResourceId}`;
        default:
            return `https://bindles-gamma.amazon.com/resource/${bindleResourceId}`;
    }
}

export default function BindleLink({bindleResourceId}) {
    return (
        <Link
            external
            target="_blank"
            href={buildBindleUrl(STAGE, bindleResourceId)}
        >
            {bindleResourceId}
        </Link>
    )
}