import {
    TenantId,
    TenantString,
    TenantOption,
    TENANT_IDS,
    TENANT_STRINGS
} from "src/@types/tenants";

class Tenant {
    id: TenantId;
    label: TenantString;

    constructor(id: TenantId, label: TenantString) {
        this.id = id;
        this.label = label;
    }

    buildOption(): TenantOption {
        return {
            value: this.id,
            label: this.label
        };
    }

    toString(): string {
        return `${this.id} (${this.label})`;
    }

    equals(otherTenant: Tenant): boolean {
        return this.id === otherTenant.id;
    }

    static buildTenantFromObject({ id, label }) {
        return new Tenant(id, label);
    }
}

class Tenants {
    static TEST_TENANT = new Tenant(
        TENANT_IDS.TEST_TENANT_ID,
        TENANT_STRINGS.TEST_TENANT_STRING
    );

    static SPS_TENANT = new Tenant(
        TENANT_IDS.SPS_TENANT_ID,
        TENANT_STRINGS.SPS_TENANT_STRING
    );

    static getTenantById(tenantId: TenantId) {
        switch (tenantId) {
            case this.TEST_TENANT.id:
                return this.TEST_TENANT;
            case this.SPS_TENANT.id:
                return this.SPS_TENANT;
            default:
                return this.TEST_TENANT;
        }
    }

    static getDefaultTenant() {
        return this.TEST_TENANT;
    }

    static list() {
        return [this.TEST_TENANT, this.SPS_TENANT];
    }

    static isBetaTenant(otherTenant: Tenant) {
        return this.TEST_TENANT.equals(otherTenant);
    }
}

const DEFAULT_TENANT = Tenants.getDefaultTenant();

export { Tenant, Tenants, DEFAULT_TENANT };
