import { API } from "aws-amplify";
import { DateRangePickerProps, MultiselectProps, SelectProps } from "@amzn/awsui-components-react/polaris";
import { buildAPIHeader } from "src/aws-config";
import { getUTCTDateTimeString } from "src/components/DateFormatter";
import { SurveyOption, SurveyQuestion } from "src/components/survey-builder/SurveyQuestionTypes";
import { AmplifyConfigType, ServiceWrapperFunction } from "src/components/wrappers/ServiceWrapperFunction";
import { DEPPRECATED_getCurrentUser } from "src/components/context/UserContext";


export class SurveyService {
    private static readonly AXIOM_ADMIN_API_NAME = "AxiomAdmin";
    private static readonly APPLICATION = "Axiom";

    /**
     * Wrapper for SurveyService calls, makes sure that the correct Amplify config is set before making the call.
     */
    private static call = ServiceWrapperFunction(AmplifyConfigType.ADMIN);

    static createQuestion = SurveyService.call(async (tenant: string,
                                                      status: SelectProps.Option,
                                                      dateRange: DateRangePickerProps.AbsoluteValue | null,
                                                      question: string,
                                                      options: SurveyOption[],
                                                      tags: MultiselectProps.Options) => {
        return API.post(SurveyService.AXIOM_ADMIN_API_NAME, "create-survey-question", {
            body: {
                application: SurveyService.APPLICATION,
                tenant_id: tenant,
                survey_status: status.value,
                author_id: DEPPRECATED_getCurrentUser().getLogin(),
                question: question,
                options: options.reduce((map, obj) => (map[obj.key] = obj.value, map), {}),
                tags: tags.map(option => option.label),
                start_date_time: getUTCTDateTimeString(dateRange?.startDate),
                end_date_time: getUTCTDateTimeString(dateRange?.endDate),
            },
            ...await buildAPIHeader()
        })
    });

    static updateQuestion = SurveyService.call(async (tenant: string,
                                                      status: SelectProps.Option,
                                                      dateRange: DateRangePickerProps.AbsoluteValue | null,
                                                      question: string,
                                                      options: SurveyOption[],
                                                      tags: MultiselectProps.Options,
                                                      surveyQuestion: SurveyQuestion) => {
        return API.post(SurveyService.AXIOM_ADMIN_API_NAME, "update-survey-question", {
            body: {
                question: {
                    application_tenant_id: surveyQuestion.application_tenant_id,
                    survey_id: surveyQuestion.survey_id,
                    application: surveyQuestion.application,
                    tenant_id: surveyQuestion.tenant_id,
                    survey_status: status.value,
                    author_id: surveyQuestion.author_id,
                    question: question,
                    options: options.reduce((map, obj) => (map[obj.key] = obj.value, map), {}),
                    tags: tags.map(option => option.label),
                    created_date_time: surveyQuestion.created_date_time,
                    start_date_time: getUTCTDateTimeString(dateRange?.startDate),
                    end_date_time: getUTCTDateTimeString(dateRange?.endDate),
                },
                entity_id: DEPPRECATED_getCurrentUser().getLogin(),
            },
            ...await buildAPIHeader()
        })
    });

    static getQuestions = SurveyService.call(async (tenant: string) => {
        return API.post(SurveyService.AXIOM_ADMIN_API_NAME, "list-survey-questions", {
            body: {
                application: SurveyService.APPLICATION,
                tenant_id: tenant
            },
            ...await buildAPIHeader()
        })
    });

    static getQuestionById = SurveyService.call(async (tenantId: string, surveyId: string) => {
        return await API.post(SurveyService.AXIOM_ADMIN_API_NAME, "get-survey-question", {
            body: {
                application: SurveyService.APPLICATION,
                tenant_id: tenantId,
                survey_id: surveyId
            },
            ...await buildAPIHeader()
        }).then(response => {
            return {
                ...response,
                options:
                    Object.keys(response.options).map((key) =>
                        ({ key: key, value: response.options[key] })),
                tags: response.tags.map((tag) => ({ label: tag, value: tag }))
            };
        })
    });

    static changeStatus = SurveyService.call(async (tenant: string, uuid: string, status: string) => {
        return API.post(SurveyService.AXIOM_ADMIN_API_NAME, "change-survey-question-status", {
            body: {
                application: SurveyService.APPLICATION,
                tenant_id: tenant,
                survey_id: uuid,
                status: status
            },
            ...await buildAPIHeader()
        }).then(response => {
            return {
                ...response,
                options:
                    Object.keys(response.options).map((key) =>
                        ({ key: key, value: response.options[key] }))
            };
        })
    });

    static getTags = SurveyService.call(async (tenant_id: string) => {
        return API.post(SurveyService.AXIOM_ADMIN_API_NAME, "get-tags", {
            body: {
                tenant_id: tenant_id
            },
            ...await buildAPIHeader()
        }).then(response => {
            return response.map((tag) => ({ label: tag, value: tag }));
        })
    });
}
