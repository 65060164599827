/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateFormDefinitionInput = {
  application: string,
  tenantId: string,
  language: string,
  formName: string,
  displayTitle: string,
  description?: string | null,
  status: string,
  formTemplateType: FormTemplateType,
  formTemplate: string,
  bindle: string,
  isMajorVersion: boolean,
  versionComment?: string | null,
  helpContentLink?: string | null,
  extensions?: Array< ExtensionInput | null > | null,
  timeToEditFormSubmissions?: number | null,
  allowDrafts: boolean,
};

// Types of Forms
export enum FormTemplateType {
  AxiomForm = "AxiomForm",
  MultipleForms = "MultipleForms",
}


export type ExtensionInput = {
  id: string,
  type?: string | null,
  name?: string | null,
  content?: Array< TupleInput | null > | null,
};

export type TupleInput = {
  key: string,
  value: string,
};

export type CreateFormDefinitionResponse = {
  __typename: "CreateFormDefinitionResponse",
  statusCode: number,
  message: string,
  body?: FormDefinition | null,
};

export type Response = {
  __typename: "Response",
  statusCode: number,
  message: string,
};

export type FormDefinitionResponse = {
  __typename: "FormDefinitionResponse",
  statusCode: number,
  message: string,
  body?: FormDefinitionResponseBody | null,
};

export type FormDefinitionResponseBody = {
  __typename: "FormDefinitionResponseBody",
  formDefinition?: FormDefinition | null,
  isEditableByUser?: boolean | null,
};

export type FormDefinition = {
  __typename: "FormDefinition",
  id: string,
  application: string,
  tenantId: string,
  language: string,
  formName: string,
  displayTitle: string,
  description?: string | null,
  version: string,
  versionComment?: string | null,
  status: FormDefinitionStatus,
  formTemplateType: FormTemplateType,
  formTemplate: string,
  createdDateTime: string,
  createdBy: string,
  helpContentLink?: string | null,
  extensions?:  Array<Extension | null > | null,
  bindle: string,
  bindleResourceId: string,
  timeToEditFormSubmissions?: number | null,
  allowDrafts: boolean,
};

// Status of Form Definition
export enum FormDefinitionStatus {
  Active = "Active",
  Inactive = "Inactive",
}


export type Extension = {
  __typename: "Extension",
  id: string,
  type?: string | null,
  name?: string | null,
  content?:  Array<Tuple | null > | null,
};

export type Tuple = {
  __typename: "Tuple",
  key: string,
  value: string,
};

export type FormDefinitionsResponse = {
  __typename: "FormDefinitionsResponse",
  statusCode: number,
  message: string,
  body?:  Array<FormDefinition | null > | null,
};

export type FormSubmissionsResponse = {
  __typename: "FormSubmissionsResponse",
  statusCode: number,
  message: string,
  body?:  Array<FormSubmission | null > | null,
};

export type FormSubmission = {
  __typename: "FormSubmission",
  id: string,
  application: string,
  tenantId: string,
  submitterEntityId: string,
  responses: string,
  formDefinitionId: string,
  formDefinitionName?: string | null,
  formDefinition?: FormDefinition | null,
  formSubmissionStatus?: string | null,
  targetEntityId?: string | null,
  createdDateTime: string,
  lastUpdatedDateTime: string,
  sharedWith?:  Array<Entity | null > | null,
};

export type Entity = {
  __typename: "Entity",
  entityId: string,
};

export type FormSubmissionResponse = {
  __typename: "FormSubmissionResponse",
  statusCode: number,
  message: string,
  body?: FormSubmission | null,
};

export type FormSubmissionUpdateResponse = {
  __typename: "FormSubmissionUpdateResponse",
  statusCode: number,
  message: string,
  body?: FormSubmission | null,
};

export type ShareFormSubmissionResponse = {
  __typename: "ShareFormSubmissionResponse",
  statusCode: number,
  message: string,
  entityId: string,
  formSubmissionId: string,
  body?: ShareFormSubmissionOutput | null,
};

export type ShareFormSubmissionOutput = {
  __typename: "ShareFormSubmissionOutput",
  formSubmissionId: string,
  entityIds: Array< string | null >,
};

export type SubmitFormInput = {
  application: string,
  tenantId: string,
  formDefinitionId: string,
  responses: string,
  receiverEntityId?: string | null,
  formSubmissionStatus?: string | null,
};

export type UpdateFormSubmissionInput = {
  application: string,
  tenantId: string,
  formDefinitionId: string,
  responses: string,
  formSubmissionId: string,
  formSubmissionStatus: string,
};

export type ShareFormSubmissionInput = {
  formSubmissionId?: string | null,
  entity?: EntityInput | null,
};

export type EntityInput = {
  entityId: string,
};

export type GetFormDefinitionInput = {
  id: string,
};

export type GetLatestFormDefinitionInput = {
  application: string,
  tenantId: string,
  language: string,
  formName: string,
};

export type GetFormDefinitionsByFormNameInput = {
  application: string,
  tenantId: string,
  language: string,
  formName: string,
};

export type GetFormSubmissionsBySubmitterInput = {
  submitterEntityId: string,
  tenantId: string,
};

export type FormSubmissionFilter = {
  formDefinitionId?: string | null,
  lastUpdatedDateTimeFrom?: string | null,
  lastUpdatedDateTimeTo?: string | null,
  tenantId: string,
  formSubmissionStatus: string,
};

export type GetFormSubmissionByIdInput = {
  id: string,
};

export type ListAvailableFormDefinitionsInput = {
  application: string,
  tenantId: string,
  language: string,
  username: string,
};

export type ListAvailableFormDefinitionsFilter = {
  includeInactive?: boolean | null,
  authorizedOnly?: boolean | null,
};

export type CreateFormDefinitionMutationVariables = {
  createFormDefinitionInput: CreateFormDefinitionInput,
};

export type CreateFormDefinitionMutation = {
  createFormDefinition?:  {
    __typename: "CreateFormDefinitionResponse",
    statusCode: number,
    message: string,
    body?:  {
      __typename: "FormDefinition",
      id: string,
      application: string,
      tenantId: string,
      language: string,
      formName: string,
      displayTitle: string,
      description?: string | null,
      version: string,
      versionComment?: string | null,
      status: FormDefinitionStatus,
      formTemplateType: FormTemplateType,
      formTemplate: string,
      createdDateTime: string,
      createdBy: string,
      helpContentLink?: string | null,
      extensions?:  Array< {
        __typename: "Extension",
        id: string,
        type?: string | null,
        name?: string | null,
        content?:  Array< {
          __typename: "Tuple",
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
      bindle: string,
      bindleResourceId: string,
      timeToEditFormSubmissions?: number | null,
      allowDrafts: boolean,
    } | null,
  } | null,
};

export type SubmitFormMutationVariables = {
  submitFormInput: SubmitFormInput,
};

export type SubmitFormMutation = {
  submitForm?:  {
    __typename: "FormSubmissionResponse",
    statusCode: number,
    message: string,
    body?:  {
      __typename: "FormSubmission",
      id: string,
      application: string,
      tenantId: string,
      submitterEntityId: string,
      responses: string,
      formDefinitionId: string,
      formDefinitionName?: string | null,
      formDefinition?:  {
        __typename: "FormDefinition",
        id: string,
        application: string,
        tenantId: string,
        language: string,
        formName: string,
        displayTitle: string,
        description?: string | null,
        version: string,
        versionComment?: string | null,
        status: FormDefinitionStatus,
        formTemplateType: FormTemplateType,
        formTemplate: string,
        createdDateTime: string,
        createdBy: string,
        helpContentLink?: string | null,
        extensions?:  Array< {
          __typename: "Extension",
          id: string,
          type?: string | null,
          name?: string | null,
          content?:  Array< {
            __typename: "Tuple",
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
        bindle: string,
        bindleResourceId: string,
        timeToEditFormSubmissions?: number | null,
        allowDrafts: boolean,
      } | null,
      formSubmissionStatus?: string | null,
      targetEntityId?: string | null,
      createdDateTime: string,
      lastUpdatedDateTime: string,
      sharedWith?:  Array< {
        __typename: "Entity",
        entityId: string,
      } | null > | null,
    } | null,
  } | null,
};

export type UpdateFormSubmissionMutationVariables = {
  updateFormSubmissionInput?: UpdateFormSubmissionInput | null,
};

export type UpdateFormSubmissionMutation = {
  updateFormSubmission?:  {
    __typename: "FormSubmissionUpdateResponse",
    statusCode: number,
    message: string,
    body?:  {
      __typename: "FormSubmission",
      id: string,
      application: string,
      tenantId: string,
      submitterEntityId: string,
      responses: string,
      formDefinitionId: string,
      formDefinitionName?: string | null,
      formDefinition?:  {
        __typename: "FormDefinition",
        id: string,
        application: string,
        tenantId: string,
        language: string,
        formName: string,
        displayTitle: string,
        description?: string | null,
        version: string,
        versionComment?: string | null,
        status: FormDefinitionStatus,
        formTemplateType: FormTemplateType,
        formTemplate: string,
        createdDateTime: string,
        createdBy: string,
        helpContentLink?: string | null,
        extensions?:  Array< {
          __typename: "Extension",
          id: string,
          type?: string | null,
          name?: string | null,
          content?:  Array< {
            __typename: "Tuple",
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
        bindle: string,
        bindleResourceId: string,
        timeToEditFormSubmissions?: number | null,
        allowDrafts: boolean,
      } | null,
      formSubmissionStatus?: string | null,
      targetEntityId?: string | null,
      createdDateTime: string,
      lastUpdatedDateTime: string,
      sharedWith?:  Array< {
        __typename: "Entity",
        entityId: string,
      } | null > | null,
    } | null,
  } | null,
};

export type ShareFormSubmissionMutationVariables = {
  shareFormSubmissionInput: ShareFormSubmissionInput,
};

export type ShareFormSubmissionMutation = {
  shareFormSubmission?:  {
    __typename: "ShareFormSubmissionResponse",
    statusCode: number,
    message: string,
    entityId: string,
    formSubmissionId: string,
    body?:  {
      __typename: "ShareFormSubmissionOutput",
      formSubmissionId: string,
      entityIds: Array< string | null >,
    } | null,
  } | null,
};

export type UnshareFormSubmissionMutationVariables = {
  shareFormSubmissionInput: ShareFormSubmissionInput,
};

export type UnshareFormSubmissionMutation = {
  unshareFormSubmission?:  {
    __typename: "ShareFormSubmissionResponse",
    statusCode: number,
    message: string,
    entityId: string,
    formSubmissionId: string,
    body?:  {
      __typename: "ShareFormSubmissionOutput",
      formSubmissionId: string,
      entityIds: Array< string | null >,
    } | null,
  } | null,
};

export type GetFormDefinitionQueryVariables = {
  getFormDefinitionInput: GetFormDefinitionInput,
};

export type GetFormDefinitionQuery = {
  getFormDefinition?:  {
    __typename: "FormDefinitionResponse",
    statusCode: number,
    message: string,
    body?:  {
      __typename: "FormDefinitionResponseBody",
      formDefinition?:  {
        __typename: "FormDefinition",
        id: string,
        application: string,
        tenantId: string,
        language: string,
        formName: string,
        displayTitle: string,
        description?: string | null,
        version: string,
        versionComment?: string | null,
        status: FormDefinitionStatus,
        formTemplateType: FormTemplateType,
        formTemplate: string,
        createdDateTime: string,
        createdBy: string,
        helpContentLink?: string | null,
        extensions?:  Array< {
          __typename: "Extension",
          id: string,
          type?: string | null,
          name?: string | null,
          content?:  Array< {
            __typename: "Tuple",
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
        bindle: string,
        bindleResourceId: string,
        timeToEditFormSubmissions?: number | null,
        allowDrafts: boolean,
      } | null,
      isEditableByUser?: boolean | null,
    } | null,
  } | null,
};

export type GetLatestFormDefinitionQueryVariables = {
  getLatestFormDefinitionInput: GetLatestFormDefinitionInput,
};

export type GetLatestFormDefinitionQuery = {
  getLatestFormDefinition?:  {
    __typename: "FormDefinitionResponse",
    statusCode: number,
    message: string,
    body?:  {
      __typename: "FormDefinitionResponseBody",
      formDefinition?:  {
        __typename: "FormDefinition",
        id: string,
        application: string,
        tenantId: string,
        language: string,
        formName: string,
        displayTitle: string,
        description?: string | null,
        version: string,
        versionComment?: string | null,
        status: FormDefinitionStatus,
        formTemplateType: FormTemplateType,
        formTemplate: string,
        createdDateTime: string,
        createdBy: string,
        helpContentLink?: string | null,
        extensions?:  Array< {
          __typename: "Extension",
          id: string,
          type?: string | null,
          name?: string | null,
          content?:  Array< {
            __typename: "Tuple",
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
        bindle: string,
        bindleResourceId: string,
        timeToEditFormSubmissions?: number | null,
        allowDrafts: boolean,
      } | null,
      isEditableByUser?: boolean | null,
    } | null,
  } | null,
};

export type GetFormDefinitionsByFormNameQueryVariables = {
  getFormDefinitionsByFormNameInput: GetFormDefinitionsByFormNameInput,
};

export type GetFormDefinitionsByFormNameQuery = {
  getFormDefinitionsByFormName?:  {
    __typename: "FormDefinitionsResponse",
    statusCode: number,
    message: string,
    body?:  Array< {
      __typename: "FormDefinition",
      id: string,
      application: string,
      tenantId: string,
      language: string,
      formName: string,
      displayTitle: string,
      description?: string | null,
      version: string,
      versionComment?: string | null,
      status: FormDefinitionStatus,
      formTemplateType: FormTemplateType,
      formTemplate: string,
      createdDateTime: string,
      createdBy: string,
      helpContentLink?: string | null,
      extensions?:  Array< {
        __typename: "Extension",
        id: string,
        type?: string | null,
        name?: string | null,
        content?:  Array< {
          __typename: "Tuple",
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
      bindle: string,
      bindleResourceId: string,
      timeToEditFormSubmissions?: number | null,
      allowDrafts: boolean,
    } | null > | null,
  } | null,
};

export type GetFormSubmissionsBySubmitterQueryVariables = {
  getFormSubmissionsBySubmitterInput: GetFormSubmissionsBySubmitterInput,
  filter?: FormSubmissionFilter | null,
};

export type GetFormSubmissionsBySubmitterQuery = {
  getFormSubmissionsBySubmitter?:  {
    __typename: "FormSubmissionsResponse",
    statusCode: number,
    message: string,
    body?:  Array< {
      __typename: "FormSubmission",
      id: string,
      application: string,
      tenantId: string,
      submitterEntityId: string,
      responses: string,
      formDefinitionId: string,
      formDefinitionName?: string | null,
      formDefinition?:  {
        __typename: "FormDefinition",
        id: string,
        application: string,
        tenantId: string,
        language: string,
        formName: string,
        displayTitle: string,
        description?: string | null,
        version: string,
        versionComment?: string | null,
        status: FormDefinitionStatus,
        formTemplateType: FormTemplateType,
        formTemplate: string,
        createdDateTime: string,
        createdBy: string,
        helpContentLink?: string | null,
        extensions?:  Array< {
          __typename: "Extension",
          id: string,
          type?: string | null,
          name?: string | null,
          content?:  Array< {
            __typename: "Tuple",
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
        bindle: string,
        bindleResourceId: string,
        timeToEditFormSubmissions?: number | null,
        allowDrafts: boolean,
      } | null,
      formSubmissionStatus?: string | null,
      targetEntityId?: string | null,
      createdDateTime: string,
      lastUpdatedDateTime: string,
      sharedWith?:  Array< {
        __typename: "Entity",
        entityId: string,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetFormSubmissionByIdQueryVariables = {
  getFormSubmissionByIdInput: GetFormSubmissionByIdInput,
};

export type GetFormSubmissionByIdQuery = {
  getFormSubmissionById?:  {
    __typename: "FormSubmissionResponse",
    statusCode: number,
    message: string,
    body?:  {
      __typename: "FormSubmission",
      id: string,
      application: string,
      tenantId: string,
      submitterEntityId: string,
      responses: string,
      formDefinitionId: string,
      formDefinitionName?: string | null,
      formDefinition?:  {
        __typename: "FormDefinition",
        id: string,
        application: string,
        tenantId: string,
        language: string,
        formName: string,
        displayTitle: string,
        description?: string | null,
        version: string,
        versionComment?: string | null,
        status: FormDefinitionStatus,
        formTemplateType: FormTemplateType,
        formTemplate: string,
        createdDateTime: string,
        createdBy: string,
        helpContentLink?: string | null,
        extensions?:  Array< {
          __typename: "Extension",
          id: string,
          type?: string | null,
          name?: string | null,
          content?:  Array< {
            __typename: "Tuple",
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
        bindle: string,
        bindleResourceId: string,
        timeToEditFormSubmissions?: number | null,
        allowDrafts: boolean,
      } | null,
      formSubmissionStatus?: string | null,
      targetEntityId?: string | null,
      createdDateTime: string,
      lastUpdatedDateTime: string,
      sharedWith?:  Array< {
        __typename: "Entity",
        entityId: string,
      } | null > | null,
    } | null,
  } | null,
};

export type GetFormSubmissionsSharedWithQueryVariables = {
  getFormSubmissionSharedWithInput: EntityInput,
  filter?: FormSubmissionFilter | null,
};

export type GetFormSubmissionsSharedWithQuery = {
  getFormSubmissionsSharedWith?:  {
    __typename: "FormSubmissionsResponse",
    statusCode: number,
    message: string,
    body?:  Array< {
      __typename: "FormSubmission",
      id: string,
      application: string,
      tenantId: string,
      submitterEntityId: string,
      responses: string,
      formDefinitionId: string,
      formDefinitionName?: string | null,
      formDefinition?:  {
        __typename: "FormDefinition",
        id: string,
        application: string,
        tenantId: string,
        language: string,
        formName: string,
        displayTitle: string,
        description?: string | null,
        version: string,
        versionComment?: string | null,
        status: FormDefinitionStatus,
        formTemplateType: FormTemplateType,
        formTemplate: string,
        createdDateTime: string,
        createdBy: string,
        helpContentLink?: string | null,
        extensions?:  Array< {
          __typename: "Extension",
          id: string,
          type?: string | null,
          name?: string | null,
          content?:  Array< {
            __typename: "Tuple",
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
        bindle: string,
        bindleResourceId: string,
        timeToEditFormSubmissions?: number | null,
        allowDrafts: boolean,
      } | null,
      formSubmissionStatus?: string | null,
      targetEntityId?: string | null,
      createdDateTime: string,
      lastUpdatedDateTime: string,
      sharedWith?:  Array< {
        __typename: "Entity",
        entityId: string,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type ListAvailableFormDefinitionsQueryVariables = {
  listAvailableFormDefinitionsInput: ListAvailableFormDefinitionsInput,
  filter?: ListAvailableFormDefinitionsFilter | null,
};

export type ListAvailableFormDefinitionsQuery = {
  listAvailableFormDefinitions?:  {
    __typename: "FormDefinitionsResponse",
    statusCode: number,
    message: string,
    body?:  Array< {
      __typename: "FormDefinition",
      id: string,
      application: string,
      tenantId: string,
      language: string,
      formName: string,
      displayTitle: string,
      description?: string | null,
      version: string,
      versionComment?: string | null,
      status: FormDefinitionStatus,
      formTemplateType: FormTemplateType,
      formTemplate: string,
      createdDateTime: string,
      createdBy: string,
      helpContentLink?: string | null,
      extensions?:  Array< {
        __typename: "Extension",
        id: string,
        type?: string | null,
        name?: string | null,
        content?:  Array< {
          __typename: "Tuple",
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
      bindle: string,
      bindleResourceId: string,
      timeToEditFormSubmissions?: number | null,
      allowDrafts: boolean,
    } | null > | null,
  } | null,
};

export type FormSubmissionSharedSubscriptionVariables = {
  formSubmissionId?: string | null,
  entityId?: string | null,
};

export type FormSubmissionSharedSubscription = {
  formSubmissionShared?:  {
    __typename: "ShareFormSubmissionResponse",
    statusCode: number,
    message: string,
    entityId: string,
    formSubmissionId: string,
    body?:  {
      __typename: "ShareFormSubmissionOutput",
      formSubmissionId: string,
      entityIds: Array< string | null >,
    } | null,
  } | null,
};
