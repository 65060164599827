import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API } from "aws-amplify";
import {
    CreateFormDefinitionMutation,
    CreateFormDefinitionMutationVariables,
    FormDefinition,
    FormDefinitionResponse,
    CreateFormDefinitionResponse,
    FormDefinitionsResponse,
    GetLatestFormDefinitionQuery,
    GetLatestFormDefinitionQueryVariables,
    ListAvailableFormDefinitionsQuery,
    GetFormDefinitionsByFormNameQueryVariables,
    GetFormDefinitionsByFormNameQuery,
    GetFormDefinitionQueryVariables,
    GetFormDefinitionQuery
} from "./amplify/appsync";
import {
    getLatestFormDefinition,
    getFormDefinition
} from "./amplify/queries";
import { createFormDefinition } from "./amplify/mutations";
import {
    AmplifyConfigType,
    ServiceWrapperFunction
} from "src/components/wrappers/ServiceWrapperFunction";

const FORMS_APPLICATION = "Axiom";

export class FormsService {

    /**
     * Wrapper for FormsService calls, makes sure that the correct Amplify config is set before making the call.
     */
    private static call = ServiceWrapperFunction(AmplifyConfigType.FORMS);

    static getFormDefinitionById = FormsService.call(async (id: string) => {
        const queryVariables: GetFormDefinitionQueryVariables = {
            getFormDefinitionInput: {
                id: id
            }
        }

        const response: GraphQLResult<GetFormDefinitionQuery> = await API.graphql({
            query: getFormDefinition,
            variables: queryVariables
        }) as GraphQLResult<GetFormDefinitionQuery>;

        if (response.errors || !response.data) {
            throw new Error(`Error getting form definition: ' ${JSON.stringify(response, null, 2)}`);
        }

        if (response.data && response.data.getFormDefinition) {
            return response.data.getFormDefinition as FormDefinitionResponse;
        }
    })

    static getLatestFormDefinition = FormsService.call(async (tenantId: string,
                                                              language: string,
                                                              formName: string) => {
        const queryVariables: GetLatestFormDefinitionQueryVariables = {
            getLatestFormDefinitionInput: {
                application: FORMS_APPLICATION,
                tenantId: tenantId,
                language: language,
                formName: formName
            }
        };

        const response: GraphQLResult<GetLatestFormDefinitionQuery> = await API.graphql({
            query: getLatestFormDefinition,
            variables: queryVariables
        }) as GraphQLResult<GetLatestFormDefinitionQuery>;

        if (response.errors || !response.data) {
            throw new Error(`Error getting form definition: ' ${JSON.stringify(response, null, 2)}`);
        }

        if (response.data && response.data.getLatestFormDefinition) {
            return response.data.getLatestFormDefinition as FormDefinitionResponse;
        }
    });

    static listAvailableFormDefinitions = FormsService.call(async (userLogin: string,
                                                                   tenantId: string,
                                                                   language: string):
    Promise<FormDefinitionsResponse | undefined> => {
        const response: GraphQLResult<ListAvailableFormDefinitionsQuery> = (await API.graphql({
            query: `
                  query ListAvailableFormDefinitions(
                    $listAvailableFormDefinitionsInput: ListAvailableFormDefinitionsInput!
                    $filter: ListAvailableFormDefinitionsFilter
                  ) {
                    listAvailableFormDefinitions(
                      listAvailableFormDefinitionsInput: $listAvailableFormDefinitionsInput
                      filter: $filter
                    ) {
                      statusCode
                      message
                      body {
                        id
                        language
                        formName
                        displayTitle
                        version
                        status
                        createdDateTime
                        createdBy
                      }
                    }
                  }
                `,
            variables: {
                listAvailableFormDefinitionsInput: {
                    application: FORMS_APPLICATION,
                    language: language,
                    tenantId: tenantId,
                    username: userLogin,
                },
                filter: {
                    includeInactive: true,
                    authorizedOnly: false
                }
            }
        })) as GraphQLResult<ListAvailableFormDefinitionsQuery>;

        if (response.errors || !response.data) {
            throw new Error(`Error getting form definitions: ' ${JSON.stringify(response, null, 2)}`);
        }

        if (response.data && response.data.listAvailableFormDefinitions) {
            return response.data.listAvailableFormDefinitions as FormDefinitionsResponse;
        }
    });

    static getFormDefinitionsByFormName = FormsService.call(async (tenantId: string,
                                                                   formName: string,
                                                                   language: string) => {
        const input: GetFormDefinitionsByFormNameQueryVariables = {
            getFormDefinitionsByFormNameInput: {
                application: FORMS_APPLICATION,
                tenantId: tenantId,
                language: language,
                formName: formName
            }
        };

        try {
            const response = await API.graphql({
                query: `
                      query GetFormDefinitionsByFormName(
                        $getFormDefinitionsByFormNameInput: GetFormDefinitionsByFormNameInput!
                      ) {
                        getFormDefinitionsByFormName(
                          getFormDefinitionsByFormNameInput: $getFormDefinitionsByFormNameInput
                        ) {
                          statusCode
                          message
                          body {
                            id
                            language
                            formName
                            displayTitle
                            version
                            versionComment
                            status
                            createdDateTime
                            createdBy
                          }
                        }
                      }
                    `,
                variables: input
            }) as GraphQLResult<GetFormDefinitionsByFormNameQuery>;

            if (response.errors || !response.data) {
                throw new Error(`Error fetching form definitions: ' ${JSON.stringify(response, null, 2)}`);
            }

            if (response.data && response.data.getFormDefinitionsByFormName) {
                return response.data.getFormDefinitionsByFormName as FormDefinitionsResponse;
            }
        } catch (e) {
            console.error(e);
            throw new Error(`Error fetching form definitions for formName ${formName} and tenant ${tenantId}`);
        }
    })

    static createFormDefinition = FormsService.call(async (formDefinition: FormDefinition,
                                                           formTemplate: string,
                                                           extensions: any,
                                                           isMajorVersion: string = '0') => {
        const queryVariables: CreateFormDefinitionMutationVariables = {
            createFormDefinitionInput: {
                application: formDefinition.application,
                tenantId: formDefinition.tenantId,
                language: formDefinition.language,
                formName: formDefinition.formName,
                status: formDefinition.status,
                displayTitle: formDefinition.displayTitle,
                formTemplateType: formDefinition.formTemplateType,
                bindle: formDefinition.bindle,
                formTemplate: formTemplate,
                isMajorVersion: isMajorVersion === '1',
                description: formDefinition.description || '',
                versionComment: formDefinition.versionComment || '',
                helpContentLink: formDefinition.helpContentLink || '',
                extensions: extensions || null,
                timeToEditFormSubmissions: formDefinition.timeToEditFormSubmissions || 0,
                allowDrafts: formDefinition.allowDrafts
            }
        };
        const response: GraphQLResult<CreateFormDefinitionMutation> = await API.graphql({
            query: createFormDefinition,
            variables: queryVariables
        }) as GraphQLResult<CreateFormDefinitionMutation>;

        if (response.errors || !response.data || !response.data.createFormDefinition
            || response.data.createFormDefinition?.statusCode != 200) {
            console.error('CreateFormDefinition response error', response)
            throw response;
        }
        return response.data.createFormDefinition as CreateFormDefinitionResponse;
    });

    static isSuccessful(formsResponse: any): boolean {
        return formsResponse && formsResponse?.statusCode === 200;
    }

}