import React, { PropsWithChildren, useEffect, useState } from "react";
import { User, UserWrapper } from "src/components/context/User";
import { AmplifyAuthenticationService } from "src/components/forms/service/AmplifyAuthenticationService";
import { getTenantFromLocalStorage } from "src/utils/localStorageUtils";
import { TENANT_KEY } from "src/@types/tenants";
import { Tenant, DEFAULT_TENANT } from "src/utils/Tenant";

let currentUser: User = new UserWrapper({});

/**
 * @deprecated Gets the current user from outside the UserContext.
 * This is required because we have services with static functions used broadly,
 * which we should refactor to use instances, maybe through a context too.
 *
 * Use the UserContext instead.
 *
 * @see UserContext
 *
 * @returns {User}
 * @constructor
 */
export function DEPPRECATED_getCurrentUser(): User {
    return currentUser;
}

export interface UserContext {
    user: User;
    setLoggedInUser: (user: any) => void;
    signOut: () => void;
    selectedTenant: Tenant;
    setSelectedTenant: Function;
}

export const setLoggedInUser = (user: any) => {
    currentUser = new UserWrapper(user);
};

const signOut = () => AmplifyAuthenticationService.signOut();

const emptyUserState: UserContext = {
    user: currentUser,
    setLoggedInUser,
    signOut,
    selectedTenant: DEFAULT_TENANT,
    setSelectedTenant: () => {}
};

export const UserContext = React.createContext<UserContext>(emptyUserState);

export const UserContextProvider = (props: PropsWithChildren<{}>) => {
    const [selectedTenant, setSelectedTenant] = useState<Tenant>(
        getTenantFromLocalStorage()
    );

    const context: UserContext = {
        user: currentUser,
        setLoggedInUser,
        signOut,
        selectedTenant,
        setSelectedTenant
    };

    useEffect(() => {
        localStorage.setItem(TENANT_KEY, selectedTenant.id);
    }, [selectedTenant]);

    return (
        <UserContext.Provider value={{ ...context }}>
            {props.children}
        </UserContext.Provider>
    );
};
