import React, { useState } from 'react';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { useNavigate } from "react-router-dom";
import { getCurrentMoment, getMomentFromDateTimeString } from "src/components/DateFormatter";
import { SurveyQuestionStatusType } from "src/components/survey-builder/SurveyQuestionTypes";

export const SurveyFormActions = ({onSave, isNewQuestion, tenantId, surveyId, startDate, surveyStatus}) => {

    let navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);

    const [showConfirmation, setShowConfirmation] = useState(false);

    const performSave = () => {
        setLoading(true);
        onSave().then((result) => {
            setLoading(false);
            if (isNewQuestion) {
                navigate('/survey-builder/', {
                    state: {
                        flash: [{
                            content: `Question created successfully with id ${result.survey_id}`,
                            type: 'success',
                            dismissible: true
                        }]
                    }
                });
            } else {
                navigate(`/survey-builder/${result.tenant_id}/${result.survey_id}`, {
                    state: {
                        flash: [{
                            content: "Question edited successfully",
                            type: 'success',
                            dismissible: true
                        }]
                    }
                });
            }
        });
    };

    const save = async () => {
        // show override modal if it's edit scenario, is active and start date already passed
        if (!isNewQuestion && surveyStatus == SurveyQuestionStatusType.Active &&
            getCurrentMoment() > getMomentFromDateTimeString(startDate)) {
            setShowConfirmation(true);
        }
        else {
            performSave();
        }
    };

    const cancel = () => {
        if (isNewQuestion) {
            navigate('/survey-builder/');
        } else {
            navigate(`/survey-builder/${tenantId}/${surveyId}`);
        }
    };

    const dismissConfirmationModal = () => {
        setShowConfirmation(false);
        setLoading(false);
    }

    return (
        <React.Fragment>
            <Modal
                onDismiss={dismissConfirmationModal}
                visible={showConfirmation}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button loading={loading} variant="link" onClick={performSave}>Confirm</Button>
                            <Button variant="primary" onClick={dismissConfirmationModal}>Cancel</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Override confirmation"
            >
                This question is already live and all previous responses collected will remain under this survey.
                Confirm that you want to proceed anyway.
            </Modal>
            <SpaceBetween direction="horizontal" size="s">
            <Button variant="link" onClick={cancel}> Cancel </Button>
            <Button variant="primary"
                    loading={loading}
                    onClick={save}> Save </Button>
            </SpaceBetween>
        </React.Fragment>
    );
};