import { TENANT_KEY, TenantId } from "src/@types/tenants";
import { Tenants, DEFAULT_TENANT } from "src/utils/Tenant";

export const getItemFromLocalStorage = (key: string) => {
    const value = window.localStorage.getItem(key);
    return value;
};

export const getTenantFromLocalStorage = () => {
    const tenantString = getItemFromLocalStorage(TENANT_KEY);

    if (tenantString) {
        return Tenants.getTenantById(tenantString as TenantId);
    }

    return DEFAULT_TENANT;
};

export default {
    getTenantFromLocalStorage,
};
