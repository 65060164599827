import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { FormDesigner } from "src/components/forms/FormDesigner";
import { Loading } from "src/components/Loading";
import { KatModal } from '@amzn/katal-react';
import { AppLayout } from '@amzn/awsui-components-react/polaris';
import { ImageViewer } from "src/components/image-viewer/ImageViewer";
import { ImageUploader } from "src/components/image-viewer/ImageUploader";
import { SurveyForm } from "src/components/survey-builder/SurveyForm";
import { SurveyBuilder } from "src/components/survey-builder/SurveyBuilder";
import { SurveyQuestionDetails } from "src/components/survey-builder/SurveyQuestionDetails";
import { AmplifyAuthenticationService } from "src/components/forms/service/AmplifyAuthenticationService";
import { AmplifyConfigType } from "src/components/wrappers/ServiceWrapperFunction";
import { FormDetailedView } from "src/components/forms/FormDetailedView";
import { FormComposer } from "src/components/forms/FormComposer";
import { UserContext, UserContextProvider } from "src/components/context/UserContext";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { Button } from "@amzn/awsui-components-react";
import { AxiomAdminTeam } from "src/components/authorization/AxiomAdminTeam";
import { AccessRestrictedWrapper } from './authorization/AccessRestrictedWrapper';
import AlertModal from 'src/components/common/AlertModal';
import { NotificationsContextProvider } from './context/NotificationsContext';

interface IProps {
}

interface IState {
    loading: boolean;
    loadingAppResources: boolean;
    authState: any;
    authData: any;
    userInfo: any;
    showRequestAccessModal: boolean;
    groups: any;
}

export class App extends React.PureComponent<IProps, IState> {
    static contextType = UserContext;

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: true,
            loadingAppResources: true,
            authState: null,
            authData: null,
            userInfo: null,
            groups: null,
            showRequestAccessModal: false
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.authenticate();
        this.setState({ loading: false, loadingAppResources: false });
    }

    private async authenticate() {
        try {
            /** Authenticating with ADMIN is enough as FORMS uses the same User Pool. **/
            const userInfoAdmin = await AmplifyAuthenticationService.authenticate(AmplifyConfigType.ADMIN);
            console.log("Admin User Info", userInfoAdmin);
            if (userInfoAdmin) {
                if (userInfoAdmin['custom:groups']?.includes(AxiomAdminTeam.ADMIN)) {
                    this.setState({ authState: 'authenticated', userInfo: userInfoAdmin });
                    this.context.setLoggedInUser(userInfoAdmin);
                } else {
                    this.setState({
                        authState: 'unauthorized', userInfo: userInfoAdmin,
                        showRequestAccessModal: true
                    });
                }
            }
            this.setState({ loadingAppResources: false });
        } catch (e) {
            console.log(e)
        }
    }

    private signOut() {
        this.context.signOut();
    }

    public render() {
        if (this.state.authState === 'unauthorized') {
            return (<div>
                    <KatModal noCloseIcon visible={this.state.showRequestAccessModal}
                              onClose={(evt: any) => evt.preventDefault()}>
                        <Alert header="Access restricted" type="error">
                            <p>
                                You don't have access to Axiom Admin App because you are not a member of team <b><a
                                href={'https://permissions.amazon.com/a/team/axiom-sps-admin'}>
                                axiom-sps-admin</a></b>.
                            </p>
                            <div>
                                Please request access and then reload your session.
                            </div>
                            <Button onClick={this.context.signOut}>Reload session</Button>
                        </Alert>
                    </KatModal>
                </div>
            );
        }

        if (this.state.loading) {
            return <AppLayout
                content={<Loading/>}
                navigation={null}
                toolsHide={true}
            />;
        }

        if (this.state.authState === 'authenticated') {
            if (this.state.loadingAppResources) {
                // App skeleton placeholder
                return (<Loading/>)
            }

            return (
                <UserContextProvider>
                    <NotificationsContextProvider>
                        <AlertModal />
                        <Router>
                            <Routes>
                                <Route path="/" element={
                                    <FormDesigner/>
                                }/>
                                <Route path="/image-viewer" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.FILES}>
                                        <ImageViewer/>
                                    </AccessRestrictedWrapper>
                                }/>
                                <Route path="/image-viewer/upload" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.FILES}>
                                        <ImageUploader/>
                                    </AccessRestrictedWrapper>
                                }/>

                                <Route path="/survey-builder" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.REVERB}>
                                        <SurveyBuilder/>
                                    </AccessRestrictedWrapper>
                                }/>
                                <Route path="/survey-builder/:tenantId/:surveyId" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.REVERB}>
                                        <SurveyQuestionDetails/>
                                    </AccessRestrictedWrapper>
                                }/>
                                <Route path="/survey-builder/:tenantId/:surveyId/edit" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.REVERB}>
                                        <SurveyForm newQuestion={false}/>
                                    </AccessRestrictedWrapper>
                                }/>
                                <Route path="/survey-builder/create" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.REVERB}>
                                        <SurveyForm newQuestion={true}/>
                                    </AccessRestrictedWrapper>
                                }/>

                                <Route path="/form-designer" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.FORMS}>
                                        <FormDesigner/>
                                    </AccessRestrictedWrapper>
                                }/>
                                <Route path="/form-designer/:tenantId/:language/:formDefinitionName" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.FORMS}>
                                        <FormDetailedView/>
                                    </AccessRestrictedWrapper>
                                }/>
                                <Route path="/form-designer/:tenantId/:language/:formDefinitionName/edit" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.FORMS}>
                                        <FormComposer newForm={false}/>
                                    </AccessRestrictedWrapper>
                                }/>
                                <Route path="/form-designer/:tenantId/:language/:formDefinitionName/:formDefinitionId/edit" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.FORMS}>
                                        <FormComposer newForm={false}/>
                                    </AccessRestrictedWrapper>
                                }/>
                                <Route path="/form-designer/create" element={
                                    <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.FORMS}>
                                        <FormComposer newForm={true}/>
                                    </AccessRestrictedWrapper>}
                                />
                            </Routes>
                        </Router>
                    </NotificationsContextProvider>
                </UserContextProvider>
            );
        }

        return (<></>);
    }
}

export default App;
