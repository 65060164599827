import React, { useState } from 'react';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { useNavigate } from "react-router-dom";

export const FormComposerActions = ({onSave, isNewForm, tenantId, language, formName, canSave}) => {

    let navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);

    const [showConfirmation, setShowConfirmation] = useState(false);

    const performSave = async () => {
        setLoading(true);
        onSave()
            .then((result) => {
                setLoading(false);
                if (isNewForm) {
                    navigate('/form-designer/', {
                        state: {
                            flash: [{
                                content: `Form created successfully with name "${result.body.formName}"`,
                                type: 'success',
                                dismissible: true
                            }]
                        }
                    });
                } else {
                    navigate(`/form-designer/${result.body.tenantId}/${result.body.language}/${result.body.formName}`, {
                        state: {
                            flash: [{
                                content: `Created new version for "${result.body.formName}" successfully`,
                                type: 'success',
                                dismissible: true
                            }]
                        }
                    });
                }
            })
            .catch(error => console.error('error in form composer', error));
    };

    const save = async () => {
        // show override modal if it's new version scenario
        if (!isNewForm) {
            setShowConfirmation(true);
        } else {
            performSave();
        }
    };

    const cancel = () => {
        if (isNewForm) {
            navigate('/form-designer/');
        } else {
            navigate(`/form-designer/${tenantId}/${language}/${formName}`);
        }
    };

    const dismissConfirmationModal = () => {
        setShowConfirmation(false);
        setLoading(false);
    };

    return (
        <React.Fragment>
            <Modal
                onDismiss={dismissConfirmationModal}
                visible={showConfirmation}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={dismissConfirmationModal}>
                                Cancel
                            </Button>
                            <Button loading={loading} variant="primary" onClick={performSave}>
                                Confirm
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="New version confirmation"
            >
                Confirm that you want to create a new version for this form
            </Modal>
            <SpaceBetween direction="horizontal" size="s">
                <Button variant="link" onClick={cancel}> Cancel </Button>
                <Button variant="primary"
                        loading={loading}
                        onClick={save}
                        disabled={!canSave}> Save </Button>
            </SpaceBetween>
        </React.Fragment>
    );
};