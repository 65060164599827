import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AppNavigation from "src/components/AppNavigation";
import AppBreadcrumbs from "src/components/AppBreadcrumbs";
import {
    AppLayout,
    Header,
    ColumnLayout,
    Alert,
    Container,
    Textarea,
    SpaceBetween,
    Table, Button, AlertProps, Badge,
} from "@amzn/awsui-components-react/polaris";
import { SurveyService } from "src/components/survey-builder/Survey.service";
import "./SurveyQuestionDetails.scss"
import { Loading } from "src/components/Loading";
import { getLocalTimezoneMoment } from "src/components/DateFormatter";
import { DecoratedFlashbar } from "src/components/DecoratedFlashbar";
import {
    SurveyOption,
    SurveyQuestion,
    SurveyQuestionStatusType
} from "src/components/survey-builder/SurveyQuestionTypes";
import { UserContext } from "src/components/context/UserContext";
import { NotificationsContext } from 'src/components/context/NotificationsContext';

interface CustomAlertProps extends AlertProps {
    alertText?: string;
}

const ActionsHeader = (props) => {

    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alertProps, setAlertProps] = useState<CustomAlertProps | undefined>(undefined);

    const onChangeStatus = () => {
        setLoading(true);
        const newStatus = props.question.survey_status === SurveyQuestionStatusType.Active ?
            SurveyQuestionStatusType.Inactive : SurveyQuestionStatusType.Active;
        SurveyService.changeStatus(props.question.tenant_id, props.question.survey_id, newStatus).then(
            (result) => {
                props.handleUpdate(result);
                setLoading(false);
                displayBanner("Success", "Status changed successfully",
                    "success");
            })
            .catch((error) => {
            displayBanner("Error", `Unable to change status: ${error.toString()}`,
                "error");
            setLoading(false);
        });
    };

    const onEdit = () => {
        navigate(`/survey-builder/${props.question.tenant_id}/${props.question.survey_id}/edit`);
    };

    const displayBanner = (header: string, alertText: string, type: AlertProps.Type) => {
        return setAlertProps({
            onDismiss: () => {
                setAlertProps(undefined);
            },
            visible: true,
            dismissible: true,
            header: header,
            alertText: alertText,
            type: type
        });
    };

    return (

    <div>
        {alertProps && <Alert {...alertProps}>{alertProps.alertText}</Alert>}
        <Header actions={
            <div>
                <SpaceBetween direction="horizontal" size="s" >
                    <Button onClick={onEdit} >
                        Edit
                    </Button>
                    <Button onClick={onChangeStatus} loading={loading} variant="primary" >
                        {props.question.survey_status === SurveyQuestionStatusType.Inactive ? 'Activate' : 'Deactivate'}
                    </Button>
                </SpaceBetween>
            </div>


        } >
            {props.question.survey_id}
        </Header>

    </div>
    );
};

const Content = (props) => {

    const [surveyQuestion, setSurveyQuestion] = useState<SurveyQuestion>({
        application_tenant_id: "",
        survey_id: "",
        application: "",
        tenant_id: "",
        start_date_time: "",
        end_date_time: "",
        survey_status: SurveyQuestionStatusType.Inactive,
        author_id: "",
        question: "",
        options: [],
        created_date_time: "",
        last_updated_date_time: "",
        last_updated_by: "",
        tags: []
    });

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState("");

    const handleUpdate = (question) => {
        setSurveyQuestion(question);
    };

    useEffect(() => {
        document.title = 'Axiom Admin - Survey Form';
        setLoading(true);
        SurveyService.getQuestionById(props.tenantId, props.surveyId)
            .then((question) => {
                setSurveyQuestion(question);
            })
            .catch(
                (error) => setError(error.toString())
            )
            .finally(
                () => setLoading(false)
            );
    }, []);

    if (loading) {
        return (
            <Loading />
        )
    } else {
        return (
            <React.Fragment>
                {error && <Alert visible={true} dismissible={true} header="Error" type="error"
                                 onDismiss={() => setError("")}> {error} </Alert>
                }
                <SpaceBetween direction="vertical" size="m">
                    <ActionsHeader question={surveyQuestion} handleUpdate={handleUpdate}/>
                    <Container
                        header={
                            <Header variant="h2">
                                General information
                            </Header>
                        }
                    >
                        <ColumnLayout columns={2} variant="text-grid">
                            <div>
                                <SpaceBetween direction="vertical" size="m">
                                    <div>
                                        <div className="label">Application</div>
                                        <div>{surveyQuestion.application}</div>
                                    </div>
                                    <div>
                                        <div className="label">Tenant</div>
                                        <div>{surveyQuestion.tenant_id}</div>
                                    </div>
                                    <div>
                                        <div className="label">Author</div>
                                        <div>{surveyQuestion.author_id}</div>
                                    </div>
                                </SpaceBetween>
                            </div>
                            <div>
                                <SpaceBetween direction="vertical" size="m">
                                    <div>
                                        <div className="label">Status</div>
                                        <div>{surveyQuestion.survey_status}</div>
                                    </div>
                                    <div>
                                        <div className="label">Start Date</div>
                                        <div>{getLocalTimezoneMoment(surveyQuestion.start_date_time) || '-'}</div>

                                    </div>
                                    <div>
                                        <div className="label">End date</div>
                                        <div>{getLocalTimezoneMoment(surveyQuestion.end_date_time) || '-'}</div>
                                    </div>
                                </SpaceBetween>
                            </div>
                        </ColumnLayout>
                    </Container>
                    <Container
                        header={
                            <Header variant="h2">
                                Question details
                            </Header>
                        }
                    >
                        <SpaceBetween direction="vertical" size="m">
                            <Textarea
                                value={surveyQuestion.question}
                                disabled
                            />
                            <Table
                                header={
                                    <Header
                                        counter={
                                            surveyQuestion.options.length
                                                ? "(" + surveyQuestion.options.length + ")"
                                                : "(0)"
                                        }
                                        description="Available answers to the question"
                                    >
                                        Options
                                    </Header>
                                }
                                columnDefinitions={
                                    [
                                        {
                                            id: "key",
                                            header: "Key",
                                            cell: (e: SurveyOption) => e.key
                                        },
                                        {
                                            id: "value",
                                            header: "Value",
                                            cell: (e: SurveyOption) => e.value,
                                        },
                                    ]
                                }
                                visibleColumns={["key", "value"]}
                                items={surveyQuestion.options}
                            />
                            <div>
                                <SpaceBetween direction="vertical" size="m" >
                                    <div className="label">Tags</div>
                                    {
                                        surveyQuestion.tags.map(function(tag, index) {
                                        return <Badge color="blue">{tag.label}</Badge>;
                                    })
                                    }
                                </SpaceBetween>
                            </div>
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
            </React.Fragment>
        );
    }
};

export const SurveyQuestionDetails = () => {
    const { tenantId, surveyId } = useParams();

    const location = useLocation();

    const { selectedTenant, setSelectedTenant } = useContext(UserContext);
    const { conditionallyDisplayTenantChangeAlert } = useContext(NotificationsContext);

    conditionallyDisplayTenantChangeAlert('survey', selectedTenant, tenantId, setSelectedTenant);

    // @ts-ignore
    let flash = location.state?.flash ?? [];

    return (
        <AppLayout
            breadcrumbs={<AppBreadcrumbs items={
                [
                    {text: 'Axiom Admin', href: '/'},
                    {text: 'Survey Builder', href: '/survey-builder'},
                    {text: 'Detail', href: '#/'}
                ]}/>}
            contentType="default"
            content={<Content tenantId={tenantId} surveyId={surveyId} />}
            navigation={<AppNavigation />}
            notifications={!!flash && <DecoratedFlashbar items={flash} />}
        />
    );
};
