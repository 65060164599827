import React from "react";
import { useFormDefinitionsByFormName } from "src/hooks/useFormDefinitionsByFormName";
import FormHistoryTable from "src/components/forms/FormHistoryTable";
import { Alert, Box, Spinner } from "@amzn/awsui-components-react/polaris";
import { FormDefinitionCache } from "src/components/forms/FormDetailedView";

interface FormHistoryProps {
    tenantId: string;
    formName: string;
    language: string;
    formDefinitionCache: React.MutableRefObject<FormDefinitionCache>;
}

export default function FormHistory(props: FormHistoryProps) {
    const { tenantId, formName, language, formDefinitionCache } = props;
    const { formDefinitions, loading, error } = useFormDefinitionsByFormName(
        tenantId,
        formName,
        language,
        formDefinitionCache,
    );

    if (loading) {
        return (
            <Box textAlign="center">
                <Spinner size="large" />
            </Box>
        );
    }

    if (error) {
        console.log('error on history', error)
        return (
            <Alert type="error"
                statusIconAriaLabel="Error"
                header={`Unable to fetch form definition history`}
            >
                {error.message}
            </Alert>
        );
    }

    return <FormHistoryTable formDefinitions={formDefinitions} />;
}
