import React, { useContext, useEffect, useState } from "react";
import AppBreadcrumbs from "src/components/AppBreadcrumbs";
import { AppLayout, Link, SelectProps } from "@amzn/awsui-components-react/polaris";
import { useLocation } from "react-router-dom";
import ResourcesTable from "src/components/forms/ResourcesTable";
import { FormsService } from "src/components/forms/service/Forms.service";
import { FormDefinition, FormDefinitionsResponse, } from "src/components/forms/service/amplify/appsync";
import { getAttributeValue } from "src/components/forms/FormDetailedView";
import { AxiomAdminTeam } from "src/components/authorization/AxiomAdminTeam";
import { AccessRestrictedWrapper } from "../authorization/AccessRestrictedWrapper";
import AppNavigation from "src/components/AppNavigation";
import { DecoratedFlashbar } from "src/components/DecoratedFlashbar";
import { UserContext } from "src/components/context/UserContext";
import locales from "src/i18n/locales";

export function Content() {
    const {user, selectedTenant} = useContext(UserContext);
    const [availableFormDefinitions, setAvailableFormDefinitions] = useState<(FormDefinition | null)[]>([]);
    const [loading, setLoading] = useState(true);    
    const [language, setLanguage] = useState<SelectProps.Option>(locales[0]);

    useEffect(() => {
        document.title = "Axiom Admin - Form Designer";
        fetchForms(selectedTenant.id, language.value);
    }, [selectedTenant, language]);

    const fetchForms = async (tenantId: string = "0", language: string = locales[0].value) => {
        setLoading(true);
        FormsService.listAvailableFormDefinitions(user.getLogin(), tenantId, language)
            .then(handlerListAvailableFormDefinitions);
    };

    const handlerListAvailableFormDefinitions = (response?: FormDefinitionsResponse) => {
        if (response) {
            setAvailableFormDefinitions(response.body ? response.body : []);
            setLoading(false);
        }
    };

    return (
        <ResourcesTable
            columnDefinitions={[
                {
                    id: "form_name",
                    header: "Form Name",
                    cell: (e) => (
                        <Link href={`#/form-designer/${selectedTenant.id}/${e.language}/${e.formName}`}>
                            {e.formName}
                        </Link>
                    ),
                    sortingField: 'formName'
                },
                {
                    id: "display_title",
                    header: "Display Title",
                    cell: (e) => e.displayTitle,
                    sortingField: "displayTitle"
                },
                {
                    id: "version",
                    header: "Version",
                    cell: (e) => e.version,
                    sortingField: "version"
                },
                {
                    id: "created_by",
                    header: "Author",
                    cell: (e) => getAttributeValue(e, "createdBy"),
                    sortingField: "createdBy"
                },
                {
                    id: "created_date",
                    header: "Version Creation Date",
                    cell: (e) => getAttributeValue(e, "createdDateTime"),
                    sortingField: "createdDateTime"
                },
                {
                    id: "status",
                    header: "Status",
                    cell: (e) => getAttributeValue(e, "status"),
                    sortingField: "status"
                }
            ]}
            items={availableFormDefinitions}
            loading={loading}
            loadingText="Loading form definitions"
            language={language}
            setLanguage={setLanguage}
        />
    );
}

export const FormDesigner = () => {

    const location = useLocation();

    // @ts-ignore
    let flash = location.state?.flash ?? [];

    return (
        <AppLayout
            breadcrumbs={
                <AppBreadcrumbs
                    items={[
                        { text: "Axiom Admin", href: "/" },
                        { text: "Form Designer", href: "#/" }
                    ]}
                />
            }
            content={
                <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.FORMS}>
                    <Content/>
                </AccessRestrictedWrapper>
            }
            contentType="table"
            navigation={<AppNavigation/>}
            notifications={!!flash && <DecoratedFlashbar items={flash}/>}
        />
    );
};
