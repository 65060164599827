import React, { useContext } from 'react';

import { AxiomAdminTeam } from 'src/components/authorization/AxiomAdminTeam';
import { UserContext } from "src/components/context/UserContext";

import Alert from "@amzn/awsui-components-react/polaris/alert";
import { Button } from "@amzn/awsui-components-react/polaris";

interface AccessRestrictedWrapperProps {
    allowedTeam: AxiomAdminTeam;
    children: React.ReactNode;
}

export const AccessRestrictedWrapper: React.FC<AccessRestrictedWrapperProps> = (props) => {

    const { allowedTeam, children } = props;

    const userContext = useContext(UserContext);

    const AccessRestrictedMessage = () => <>
        <Alert header="Access restricted" type="error">
            <p>
                You are not a member of team <b><a href={`https://permissions.amazon.com/a/team/${allowedTeam}`}>
                {allowedTeam}.</a></b>
            </p>
            <div>
                Please select a different tool or request access and then reload your session.
            </div>
            <Button onClick={userContext.signOut}>Reload session</Button>
        </Alert>
    </>;

    return <>{
        userContext.user.isMemberOfTeam(allowedTeam)
            ? children
            : <AccessRestrictedMessage/>
    }</>;
}
