import React from 'react';
import { Button, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { useNavigate } from "react-router-dom";
import { BUCKET, REGION } from '../../aws-config'

export const ImageUploaderActions = ({ onUpload }: {onUpload}) => {

    let navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);

    const getImageUrl = (key: string) => {
        return `https://${BUCKET}.s3.${REGION}.amazonaws.com/${key}`
    };

    const upload = async () => {
        setLoading(true);
        onUpload().then((result) => {
            setLoading(false);
            navigate('/image-viewer/', {
                state: {
                    flash: result.map((item) => ({
                        content: `Image uploaded successfully to ${getImageUrl(item.key)}`,
                        type: 'success',
                        dismissible: true
                    }))
                }
            });
        });
    };

    const cancel = () => {
        navigate('/image-viewer/');
    };

    return (
        <React.Fragment>
            <SpaceBetween direction="horizontal" size="s">
            <Button variant="link" onClick={cancel}> Cancel </Button>
            <Button variant="primary"
                    loading={loading}
                    onClick={upload}> Upload </Button>
            </SpaceBetween>
        </React.Fragment>
    );
};