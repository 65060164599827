import moment, { Moment } from "moment-timezone";

/**
 * Returns a the ISO string representation of a UTC date based on a local date
 */
export function getUTCTDateTimeString(timestamp: string | undefined) {
        return timestamp ? moment(timestamp).utc().toISOString() : null;
}

/**
 * Returns a string representing an UTC date time in the local browser timezone
 */
export function getLocalTimezoneMoment(timestamp: string): string {
        return moment(timestamp).tz(moment.tz.guess()).format();
}

/**
 * Returns a string representing an UTC date in the local browser timezone, formats with
 * just the date
 */
export function getLocalTimezoneDate(timestamp: string): string {
        return moment(timestamp).tz(moment.tz.guess()).format('YYYY-MM-DD');
}

/**
 * Returns a Moment object from a date time string in ISO format
 */
export function getMomentFromDateTimeString(timestamp: string): Moment {
        return moment(timestamp);
}

/**
 * Returns a Moment object based on the current date time
 */
export function getCurrentMoment(): Moment {
        return moment();
}

/**
 * Returns a Moment object from a date time string in Absolute format
 */
export function getAbsoluteMoment(timestamp: string): string {
        return moment(timestamp).format('MMMM DD, YYYY, HH:mm (UTC Z)');
}