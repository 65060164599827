import React from "react";
import AppNavigation from "src/components/AppNavigation";
import AppBreadcrumbs from "src/components/AppBreadcrumbs";
import { AppLayout } from "@amzn/awsui-components-react/polaris";
import { ImageService } from "src/components/image-viewer/Image.service";
import { useLocation } from "react-router-dom";
import { DecoratedFlashbar } from "src/components/DecoratedFlashbar";
import ResourcesTable from "src/components/image-viewer/ResourcesTable";
import { AxiomAdminTeam } from "src/components/authorization/AxiomAdminTeam";
import { AccessRestrictedWrapper } from "src/components/authorization/AccessRestrictedWrapper";

interface IProps {
}

interface IState {
    images: any[];
    loading: boolean;
    s3List: any;
}

class Content extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            loading: true,
            s3List: ""
        };
    }

    public componentDidMount() {
        document.title = "Axiom Admin - Image Viewer";
        this.load();
    }

    private hasImageExtension(fileName: string | undefined) {
        return fileName ? (/\.(jpe?g|png)$/i).test(fileName) : false;
    }

    public load = () => {
        this.setState({ loading: true });
        ImageService.getImages()
            .then(({results}) => results.filter((file) => this.hasImageExtension(file.key)))
            .then((images) =>
                this.setState({
                    images: images.map((item) => ({
                        key: item.key,
                        lastModified: item.lastModified?.toISOString(),
                        size: `${item.size ? (item.size / 1000).toFixed(1) : 0} KB`
                    })),
                    loading: false
                })
            );
    };

    public render() {
        const { images, loading } = this.state;
        return (
            <ResourcesTable
                columnDefinitions={[
                    {
                        id: "id",
                        header: "Image name",
                        cell: (e) => e.key,
                        sortingField: "name"
                    },
                    {
                        id: "date",
                        header: "Last modified date",
                        cell: (e) => e.lastModified,
                        sortingField: "alt"
                    },
                    {
                        id: "size",
                        header: "Size",
                        cell: (e) => e.size
                    }
                ]}
                items={images}
                loading={loading}
                loadingText="Loading image metadata"
                refresh={this.load}
            />
        );
    }
}

export const ImageViewer = () => {
    const location = useLocation();
    // @ts-ignore
    let flash = location.state?.flash ? location.state?.flash : [];

    return (
        <AppLayout
            breadcrumbs={
                <AppBreadcrumbs
                    items={[
                        { text: "Axiom Admin", href: "/" },
                        { text: "Image Viewer", href: "#/" }
                    ]}
                />
            }
            content={
                <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.FILES}>
                    <Content/>
                </AccessRestrictedWrapper>
            }
            contentType="table"
            navigation={<AppNavigation/>}
            notifications={!!flash && <DecoratedFlashbar items={flash}/>}
        />
    );
};
