import React, { useEffect, useState, useContext } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { Box, CollectionPreferences, Pagination, RadioGroup, Table, TextFilter } from "@amzn/awsui-components-react";
import { Alert, AlertProps, Button, Header, Modal, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import { useNavigate } from "react-router-dom";
import { ImageService } from "src/components/image-viewer/Image.service";
import "./ResourcesTable.scss";
import { setStorageToBeta, setStorageToProd } from "src/aws-config";
import { UserContext } from "src/components/context/UserContext";
import { Tenants } from "src/utils/Tenant";

interface IProps {
    columnDefinitions: any[];
    items: any[];
    loading: boolean;
    loadingText: string;
    refresh: () => void;
}

interface CustomAlertProps extends AlertProps {
    alertText?: string;
}

const PAGE_SIZE_OPTIONS = [
    { value: 10, label: "10 Images" },
    { value: 20, label: "20 Images" },
    { value: 30, label: "30 Images" }
];

const Preferences = ({ preferences, setPreferences }) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        pageSizePreference={{
            title: "Page size",
            options: PAGE_SIZE_OPTIONS
        }}
    />
);

const TableHeader = (props) => {
    return (
        <Header variant={props.variant} description={props.description} actions={props.actionButtons}>
            {props.title}
        </Header>
    );
};

const FullPageHeader = ({ resourceName = "Image Viewer", ...props }) => {
    const [preview, setPreview] = useState("");

    const [deleteCandidates, setDeleteCandidates] = useState([]);

    const [promoteToProdCandidates, setPromoteToProdCandidates] = useState([]);

    const [deleteLoading, setDeleteLoading] = useState(false);

    const [promoteToProdLoading, setPromoteToProdLoading] = useState(false);

    const [alertBannerProps, setAlertBannerProps] = useState<CustomAlertProps | undefined>();

    const isOnlyOneSelected = props.selectedItems.length === 1;

    const isAtLeastOneSelected = props.selectedItems.length >= 1;

    let navigate = useNavigate();

    const displayBanner = (header: string, alertText: string, type: AlertProps.Type) => {
        return setAlertBannerProps({
            onDismiss: () => {
                setAlertBannerProps(undefined);
            },
            visible: true,
            dismissible: true,
            header: header,
            alertText: alertText,
            type: type
        });
    };

    const onUploadImage = async () => {
        navigate("/image-viewer/upload");
    };

    const onDeleteImage = async () => {
        setDeleteCandidates(props.selectedItems);
    };

    const onConfirmDeleteImages = async () => {
        setDeleteLoading(true);
        await ImageService.deleteImages(props.selectedItems)
            .then((result) => {
                setDeleteCandidates([]);
                setDeleteLoading(false);
                props.refresh();
                // TODO: Add Flashbar with delete notification
            })
            .catch((err) => {
                setDeleteLoading(false);
                setDeleteCandidates([]);
                console.log(err.toString());
            });
    };

    const onPreviewImage = async () => {
        ImageService.getImage(props.selectedItems[0].key).then((image) => {
            setPreview(image);
        });
    };

    const onPromoteToProd = async () => setPromoteToProdCandidates(props.selectedItems);

    const onConfirmPromoteToProd = async () => {
        setAlertBannerProps(undefined);
        setPromoteToProdLoading(true);
        await ImageService.promoteToProd(props.selectedItems.map((item) => item.key))
            .then((value) => {
                displayBanner("Success", "Images have been promoted to Prod successfully.", "success");
                setPromoteToProdLoading(false);
                setPromoteToProdCandidates([]);
            }).catch((reason) => {
                displayBanner("Error", `Error uploading images to Prod: "${reason}"`, "error");
                setPromoteToProdLoading(false);
                setPromoteToProdCandidates([]);
            });
    };

    return (
        <div>
            <Modal
                data-testid="preview-modal"
                onDismiss={() => setPreview("")}
                visible={!!preview}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                data-testid="preview-modal-close-button"
                                onClick={() => setPreview("")}
                                variant="primary"
                            >
                                Close
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Preview image"
            >
                <img src={preview} className="preview" />
            </Modal>

            <Modal
                data-testid="delete-modal"
                onDismiss={() => setDeleteCandidates([])}
                visible={!!deleteCandidates.length}
                closeAriaLabel="Close modal"
                size="small"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                data-testid="delete-modal-cancel-button"
                                onClick={() => setDeleteCandidates([])}
                                variant="normal"
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid="delete-modal-confirm-button"
                                onClick={() => onConfirmDeleteImages()}
                                variant="primary"
                                loading={deleteLoading}
                            >
                                Confirm
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Delete images"
            >
                Delete {props.selectedItems.length} image(s)?
            </Modal>

            <Modal
                data-testid="promote-modal"
                onDismiss={() => setPromoteToProdCandidates([])}
                visible={!!promoteToProdCandidates.length}
                closeAriaLabel="Close modal"
                size="small"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                data-testid="promote-modal-delete-button"
                                onClick={() => setPromoteToProdCandidates([])}
                                variant="normal"
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid="promote-modal-confirm-button"
                                onClick={() => onConfirmPromoteToProd()}
                                variant="primary"
                                loading={promoteToProdLoading}
                            >
                                Confirm
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Promote images"
            >
                Promote {props.selectedItems.length} image(s)?
            </Modal>

            <SpaceBetween size="s" direction="vertical">
                {alertBannerProps && <Alert {...alertBannerProps}>{alertBannerProps.alertText}</Alert>}
                <TableHeader
                    variant="awsui-h1-sticky"
                    title={resourceName}
                    actionButtons={
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button data-testid="preview-button" disabled={!isOnlyOneSelected} onClick={onPreviewImage}>
                                Preview
                            </Button>
                            <Button
                                data-testid="delete-button"
                                disabled={!isAtLeastOneSelected}
                                onClick={onDeleteImage}
                            >
                                Delete
                            </Button>
                            {props.isBetaView && (
                                <Button data-testid="upload-button" variant="primary" onClick={onUploadImage}>
                                    Upload images
                                </Button>
                            )}
                            {props.isBetaView && (
                                <Button
                                    data-testid="promote-button"
                                    disabled={!isAtLeastOneSelected}
                                    variant="primary"
                                    onClick={onPromoteToProd}
                                >
                                    Promote to Prod
                                </Button>
                            )}
                        </SpaceBetween>
                    }
                    {...props}
                />
            </SpaceBetween>
        </div>
    );
};

function EmptyState({ title, subtitle }) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: "s" }} color="inherit">
                {subtitle}
            </Box>
        </Box>
    );
}

export default function ResourcesTable(props: IProps) {
    const [preferences, setPreferences] = useState({
        pageSize: 20,
        visibleContent: ["id", "date", "size"],
        wrapLines: false
    });

    const { selectedTenant } = useContext(UserContext);
    const [preview, setPreview] = useState(false);
    const [isBetaView, setIsBetaView] = useState(Tenants.isBetaTenant(selectedTenant));

    useEffect(() => {
        setIsBetaView(Tenants.isBetaTenant(selectedTenant));
    }, [selectedTenant]);

    useEffect(() => {
        changeStorage();
    }, [isBetaView]);

    const changeStorage = async () => {
        if (isBetaView) {
            await setStorageToBeta();
        } else {
            await setStorageToProd();
        }
        props.refresh();
    };

    const { items, collectionProps, filterProps, paginationProps } = useCollection(props.items, {
        filtering: {
            empty: <EmptyState title="No images" subtitle="No images to display" />,
            noMatch: <EmptyState title="No matches" subtitle="Can't find a match" />
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {}
    });

    const { selectedItems } = collectionProps;

    return (
        <Table
            {...collectionProps}
            selectionType="multi"
            header={
                <FullPageHeader
                    selectedItems={selectedItems}
                    totalItems={items}
                    refresh={props.refresh}
                    isBetaView={isBetaView}
                    setIsBetaView={setIsBetaView}
                >
                    Image Viewer
                </FullPageHeader>
            }
            loading={props.loading}
            loadingText={props.loadingText}
            columnDefinitions={props.columnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            pagination={<Pagination {...paginationProps} />}
            filter={<TextFilter {...filterProps} filteringPlaceholder="Find images" />}
            preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
        />
    );
}
