import { MultiselectProps } from "@amzn/awsui-components-react/polaris";


export type SurveyOption = {
    key: string;
    value: string;
};

export type SurveyQuestion = {
    application_tenant_id: string;
    survey_id: string;
    application: string;
    tenant_id: string;
    start_date_time: string;
    end_date_time: string;
    survey_status: string;
    author_id: string;
    question: string;
    options: SurveyOption[];
    tags: MultiselectProps.Options;
    created_date_time: string;
    last_updated_date_time: string;
    last_updated_by: string;
};

export enum SurveyQuestionStatusType {
    Active = "active",
    Inactive = "inactive",
}

export type SurveyQuestionStatus = SurveyQuestionStatusType.Active | SurveyQuestionStatusType.Inactive;
