import React, { useEffect, useState, useContext } from "react";
import AppNavigation from "src/components/AppNavigation";
import AppBreadcrumbs from "src/components/AppBreadcrumbs";
import { AppLayout, Link } from "@amzn/awsui-components-react/polaris";
import { useLocation } from "react-router-dom";
import { DecoratedFlashbar } from "src/components/DecoratedFlashbar";
import ResourcesTable from "src/components/survey-builder/ResourcesTable";
import { SurveyService } from "src/components/survey-builder/Survey.service";
import { getLocalTimezoneDate } from "src/components/DateFormatter";
import { AccessRestrictedWrapper } from "src/components/authorization/AccessRestrictedWrapper";
import { AxiomAdminTeam } from "src/components/authorization/AxiomAdminTeam";
import { UserContext } from "src/components/context/UserContext";


function Content() {

    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const { selectedTenant } = useContext(UserContext);

    useEffect(() => {
        document.title = "Axiom Admin - Survey Builder";
    }, []);

    useEffect(() => {
        load();
    }, [selectedTenant]);

    const load = () => {
        setLoading(true);
        SurveyService.getQuestions(selectedTenant.id)
            .then((questions) => {
                setQuestions(questions ? questions : []);
                setLoading(false);
            });
    };

    return (
        <ResourcesTable
            columnDefinitions={[
                {
                    id: "id",
                    header: "Id",
                    cell: (e) => (
                        <div>
                            <Link href={`/#/survey-builder/${e.tenant_id}/${e.survey_id}`}>{e.survey_id}</Link>
                        </div>
                    )
                },
                {
                    id: "question",
                    header: "Question",
                    cell: (e) => e.question
                },
                {
                    id: "survey_status",
                    header: "Status",
                    cell: (e) => e.survey_status
                },
                {
                    id: "author_id",
                    header: "Author",
                    cell: (e) => e.author_id
                },
                {
                    id: "start_date",
                    header: "Start Date",
                    cell: (e) => getLocalTimezoneDate(e.start_date_time)
                },
                {
                    id: "end_date",
                    header: "End Date",
                    cell: (e) => getLocalTimezoneDate(e.end_date_time)
                }
            ]}
            items={questions}
            loading={loading}
            loadingText="Loading survey questions"
        />
    );
}

export const SurveyBuilder = () => {
    const location = useLocation();

    // @ts-ignore
    let flash = location.state?.flash ?? [];

    return (
        <AppLayout
            breadcrumbs={
                <AppBreadcrumbs
                    items={[
                        { text: "Axiom Admin", href: "/" },
                        { text: "Survey Builder", href: "#/" }
                    ]}
                />
            }
            content={
                <AccessRestrictedWrapper allowedTeam={AxiomAdminTeam.REVERB}>
                    <Content/>
                </AccessRestrictedWrapper>
            }
            contentType="table"
            navigation={<AppNavigation/>}
            notifications={!!flash && <DecoratedFlashbar items={flash}/>}
        />
    );
};
