import {
    ExtensionInput,
    FormDefinitionResponse,
    FormDefinitionStatus,
    FormTemplateType
} from "src/components/forms/service/amplify/appsync";

export const EXAMPLE_EXTENSIONS: ExtensionInput[] = [
    {
        id: "example_id",
        name: "Example name",
        type: "metadata",
        content:
            [
                {
                    key: "type",
                    value: "agent"
                },
                {
                    key: "source_question_id",
                    value: "associate_login"
                }
            ]
    }
];

export const EXAMPLE_FORM_TEMPLATE = {
    "sections":
        [
            {
                "title": "Section number one",
                "instructions": "Complete the fields below.",
                "sections":
                    [
                        {
                            "title": "This is the first sub-section",
                            "instructions": "Provide some information",
                            "sections":
                                [],
                            "questions":
                                [
                                    {
                                        "id": "s1_single",
                                        "uiElement": "SINGLE",
                                        "required": true,
                                        "text": "Select one option",
                                        "options":
                                            [
                                                {
                                                    "text": "Option 1",
                                                    "value": "OPTION_1"
                                                },
                                                {
                                                    "text": "Option 2",
                                                    "value": "OPTION_2"
                                                },
                                                {
                                                    "text": "This is option 3",
                                                    "value": "OPTION_3"
                                                }
                                            ],
                                        "tooltip": "This is a tooltip for a SINGLE selection question"
                                    },
                                    {
                                        "id": "s1_text",
                                        "uiElement": "TEXT",
                                        "metadataMap":
                                            {
                                                "placeholder": "This is a placeholder in an optional text question"
                                            },
                                        "text": "Say something in a single line input...",
                                        "tooltip": "This is a tooltip for a single line TEXT input"
                                    },
                                    {
                                        "id": "s1_dropdown",
                                        "uiElement": "DROPDOWN",
                                        "required": true,
                                        "text": "Select an option from a searchable DROPDOWN",
                                        "options":
                                            [
                                                {
                                                    "text": "Dropdown option 1",
                                                    "value": "D_OPTION_1"
                                                },
                                                {
                                                    "text": "Option 2",
                                                    "value": "D_OPTION_2"
                                                },
                                                {
                                                    "text": "A third option to choose",
                                                    "value": "D_OPTION_3"
                                                },
                                                {
                                                    "text": "4th option",
                                                    "value": "D_OPTION_4"
                                                },
                                                {
                                                    "text": "Last but not least!",
                                                    "value": "D_OPTION_5",
                                                }
                                            ],
                                        "tooltip": "This is a tooltip in a DROPDOWN uiElement."
                                    },
                                    {
                                        "id": "s1_date",
                                        "uiElement": "DATE",
                                        "text": "Some Date here",
                                        "tooltip": "This is a tooltip in a DATE uiElement."
                                    }
                                ]
                        },
                        {
                            "title": "Conditional questions",
                            "instructions": "Questions an be shown/hidden based on previous responses",
                            "sections":
                                [],
                            "questions":
                                [
                                    {
                                        "id": "s2_fight_flight",
                                        "uiElement": "SINGLE",
                                        "text": "Fight Or Flight?",
                                        "options":
                                            [
                                                {
                                                    "text": "Fight",
                                                    "value": "s2_fight"
                                                },
                                                {
                                                    "text": "Flight",
                                                    "value": "s2_flight"
                                                }
                                            ]
                                    },
                                    {
                                        "id": "s2_fight_sure",
                                        "uiElement": "SINGLE",
                                        "conditionMap": {
                                            "s2_fight_flight": "s2_fight"
                                        },
                                        "required": true,
                                        "text": "Are you sure you want to FIGHT?",
                                        "options":
                                            [
                                                {
                                                    "text": "Yes.",
                                                    "value": "YES"
                                                },
                                                {
                                                    "text": "Not really...",
                                                    "value": "NOT_REALLY"
                                                }
                                            ],
                                        "tooltip": "This is a tooltip in a DROPDOWN uiElement."
                                    },
                                    {
                                        "id": "s2_flight_run_or_hide",
                                        "uiElement": "DROPDOWN",
                                        "conditionMap": {
                                            "s2_fight_flight": "s2_flight"
                                        },
                                        "required": true,
                                        "text": "What's your take on flight?",
                                        "options":
                                            [
                                                {
                                                    "text": "Run",
                                                    "value": "RUN"
                                                },
                                                {
                                                    "text": "Hide",
                                                    "value": "HIDE"
                                                }
                                            ],
                                        "tooltip": "This is visible because `s2_fight_flight` is `s2_flight`"
                                    },
                                    {
                                        "id": "s2_flight_run_fast_or_slow",
                                        "uiElement": "TEXT",
                                        "conditionMap": {
                                            "s2_flight_run_or_hide": "RUN"
                                        },
                                        "metadataMap": {
                                            "regexpValidation": "^(fast|slow)$",
                                            "validationErrorText": "Should be either `fast` or `slow`",
                                        },
                                        "required": true,
                                        "text": "Fast or slow? (regex validated)",
                                        "tooltip": "Options are either fast or slow"
                                    },
                                    {
                                        "id": "s2_hide_where",
                                        "uiElement": "TEXTAREA",
                                        "logicalConditionMap":
                                            {
                                                "not":
                                                    {
                                                        "s2_flight_run_or_hide": "RUN"
                                                    }
                                            },
                                        "required": true,
                                        "text": "Since you wouldn't run, where to hide?"
                                    },
                                ]
                        }
                    ],
                "questions":
                    []
            }
        ]
};
export const EXAMPLE_FORM: FormDefinitionResponse = {
    __typename: "FormDefinitionResponse",
    "body": {
        __typename: "FormDefinitionResponseBody",
        "formDefinition": {
            __typename: "FormDefinition",
            "application": "Axiom",
            "createdBy": "tester",
            "createdDateTime": "2022-08-19T11:55:26",
            "description": "This is an example form definition",
            "displayTitle": "Example form definition",
            "formName": "example-form",
            "formTemplate": JSON.stringify(EXAMPLE_FORM_TEMPLATE),
            "formTemplateType": FormTemplateType.AxiomForm,
            "helpContentLink": "https://w.amazon.com/bin/view/CTPS/WorkforceManagement/Axiom/HelpContent/",
            "id": "example-form",
            "language": "en-us",
            "status": FormDefinitionStatus.Active,
            "tenantId": "1",
            "version": "1.0",
            "versionComment": "Initial version",
            "bindle": "WFMAxiomFormsPermissions",
            "bindleResourceId": "some_resource_id",
            "allowDrafts": false
        }
    },
    "message": "SUCCESS",
    "statusCode": 200
};

