import React from "react";
import {
    Link,
    Table,
    Header,
    TextFilter,
    Pagination
} from "@amzn/awsui-components-react/polaris";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { FormDefinition } from "src/components/forms/service/amplify/appsync";
import EmptyTable from "src/components/forms/EmptyTable";
import { VERSION_ID_QUERY_PARAM_NAME, getAttributeValue } from "src/components/forms/FormDetailedView"

type FormHistoryTableProps = {
    formDefinitions: FormDefinition[];
};

export const buildDetailsLink = (formDefinition: FormDefinition) => {
    const { tenantId, language, formName, id } = formDefinition;
    return `/#/form-designer/${tenantId}/${language}/${formName}?${VERSION_ID_QUERY_PARAM_NAME}=${id}`;
};

export default function FormHistoryTable(props: FormHistoryTableProps) {
    const { formDefinitions } = props;

    const columnDefinitions = [
        {
            id: "id",
            header: "ID",
            cell: (formDefinition: FormDefinition) => (
                <Link href={buildDetailsLink(formDefinition)}>
                    {formDefinition.id}
                </Link>
            ),
            sortingField: "id"
        },
        {
            id: "displayTitle",
            header: "Display Title",
            cell: (formDefinition: FormDefinition) =>
                formDefinition.displayTitle,
            sortingField: "displayTitle"
        },
        {
            id: "versionComment",
            header: "Version Comment",
            cell: (formDefinition: FormDefinition) =>
                getAttributeValue (formDefinition, 'versionComment'),
            sortingField: "versionComment"
        },
        {
            id: "version",
            header: "Version Number",
            cell: (formDefinition: FormDefinition) => formDefinition.version,
            sortingField: "version"
        },
        {
            id: "createdBy",
            header: "Author",
            cell: (formDefinition: FormDefinition) => 
                getAttributeValue(formDefinition, 'createdBy'),
            sortingField: "createdBy"
        },
        {
            id: "createdDateTime",
            header: "Version Created Date",
            cell: (formDefinition: FormDefinition) =>
                getAttributeValue(formDefinition, 'createdDateTime'),
            sortingField: "createdDateTime"
        }
    ];

    const { items, collectionProps, filterProps, paginationProps } =
        useCollection(formDefinitions, {
            sorting: {
                defaultState: {
                    sortingColumn: columnDefinitions[4]
                },
            },
            pagination: {
                pageSize: 10
            },
            filtering: {
                empty: (
                    <EmptyTable
                        title="No history found"
                        subtitle="No versions to display"
                    />
                ),
                noMatch: (
                    <EmptyTable
                        title="No results"
                        subtitle="We couldn't find a match for your search."
                    />
                )
            }
        });

    return (
        <Table
            {...collectionProps}
            columnDefinitions={columnDefinitions}
            items={items}
            header={
                <Header
                    variant="h1"
                    description={"List of versions submited for this form"}
                >
                    Form Version History
                </Header>
            }
            pagination={<Pagination {...paginationProps} />}
            filter={
                <TextFilter
                    {...filterProps}
                    filteringAriaLabel="Search versions"
                />
            }
        />
    );
}
