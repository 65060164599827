import * as React from 'react';
import { Component } from 'react';
import {
    Flashbar, FlashbarProps, Link
} from '@amzn/awsui-components-react/polaris';

interface IState {
    items: FlashbarProps.MessageDefinition[]
}
export class DecoratedFlashbar extends Component<FlashbarProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            items: props.items
        };
    }

    private decorateHtmlLinks = (content) => {
        const url_exp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

        return content.split(' ').map(word => {
            if (url_exp.test(word)) {
                return <Link href={word} color="inverted" external >{word + ' '} </Link>;
            }

            return word + ' ';
        });
    };

    private onDismiss = (item) => {
        this.setState({items: this.state.items.filter(stateItem => stateItem.content !== item.content)})
    };

    render() {
        const { items } = this.state;
        return (
            <Flashbar items={items.map((item) =>
                ({...item, content: this.decorateHtmlLinks(item.content),
                    onDismiss: () => this.onDismiss(item)}))} />
        );
    }
}