import { API, Storage } from "aws-amplify";
import { buildAPIHeader } from "src/aws-config";
import { AmplifyConfigType, ServiceWrapperFunction } from "src/components/wrappers/ServiceWrapperFunction";

export class ImageService {

    /**
     * Wrapper for ImageService calls, makes sure that the correct Amplify config is set before making the call.
     */
    private static call: Function = ServiceWrapperFunction(AmplifyConfigType.ADMIN);

    static getImages = ImageService.call(
        async () => {
            return await Storage.list("", {
                customPrefix: {
                    public: ""
                }
            });
        }
    );
    static getImage = ImageService.call(async (key: string) => {
        return Storage.get(key, {
            customPrefix: {
                public: ""
            }
        });
    });
    static uploadFile = ImageService.call((path: string, file: File) => {
        return Storage.put(`${path}${file.name}`, file, {
            customPrefix: {
                public: ""
            },
            contentType: file.type,
            acl: "bucket-owner-full-control"
        }) as Promise<{ key: string }>;
    });
    static uploadFiles = ImageService.call((path: string, files: File[]) => {
        return Promise.all(files.map((f) => ImageService.uploadFile(path, f)));
    });
    static deleteImage = ImageService.call((key: string) => {
        return Storage.remove(key, {
            customPrefix: {
                public: ""
            }
        });
    });
    static deleteImages = ImageService.call((images: any[]) => {
        return Promise.all(images.map((image) => ImageService.deleteImage(image.key)));
    });
    static promoteToProd = ImageService.call(async (sourceKeys: string[]) => {
        const destinationBuckets: string[] = [
            "wfmaxiomglobalstack-prod-axiomnarrativetemplatesb-cb2o0z6eht0d",
            "wfmaxiomglobalstack-prod-axiomnarrativetemplatesb-t5v39ndxpup",
            "wfmaxiomglobalstack-prod-axiomnarrativetemplatesb-vuaqor060emv"
        ];

        return Promise.all(
            destinationBuckets.map(async (destinationBucket) =>
                API.post(this.AXIOM_ADMIN_API_NAME, "copy-s3-resources", {
                    body: {
                        source_bucket: "wfmaxiomglobalstack-beta-axiomnarrativetemplatesb-1c1vzl1huqu24",
                        destination_bucket: destinationBucket,
                        source_keys: sourceKeys
                    },
                    ...await buildAPIHeader()
                })
            )
        );
    });
    private static readonly AXIOM_ADMIN_API_NAME = "AxiomAdmin";
}
