import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, SideNavigation, ColumnLayout } from '@amzn/awsui-components-react/polaris';
import { IS_BETA } from "src/aws-config";
import TenantSelector from './common/TenantSelector'
import { NotificationsContext } from 'src/components/context/NotificationsContext';


const header = {text: 'Axiom Admin', href: '/'};

const AppNavigation = () => {
    const context = useContext(NotificationsContext)
    let navigate = useNavigate();
    let location = useLocation();

    const onFollowHandler = (ev) => {
        if (ev.detail.external) {
            return;
        }

        ev.preventDefault();
        if (ev.detail.href) {
            navigate(ev.detail.href);
        }
    };

    return (
        <div className="navbar">
            <div className="beta-badge">
                {IS_BETA ? <Badge color="blue">Beta</Badge> : ""}
            </div>
            <div id='tenantSelector'>
                <TenantSelector />
            </div>
            <SideNavigation items={[
                {
                    text: 'Form Designer',
                    type: 'link',
                    href: '/form-designer'
                },
                {
                    text: 'Image Viewer',
                    type: 'link',
                    href: '/image-viewer'
                },
                {
                    text: 'Survey Builder',
                    type: 'link',
                    href: '/survey-builder'
                },
                {
                    type: 'divider'
                },
                {
                    text: 'Report a Problem',
                    type: 'link',
                    href: 'https://t.corp.amazon.com/create/templates/ee81b715-5661-4332-bbf5-1a9c3bd7733a',
                    external: true
                }
            ]} header={header} onFollow={onFollowHandler} activeHref={location.pathname} />
        </div>);
};

export default AppNavigation;
