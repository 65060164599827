import React from "react";
import { Box } from "@amzn/awsui-components-react";

type EmptyTableProps = {
    title: string,
    subtitle: string,
    children?: React.ReactNode
}

export default function EmptyTable(props: EmptyTableProps) {
    const { title, subtitle, children } = props;
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: "s" }} color="inherit">
                {subtitle}
            </Box>
            {children}
        </Box>
    );
}