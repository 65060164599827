import * as React from 'react';
import './DragAndDrop.scss';

interface IProps {
    onDrop: (files: File[]) => void;
    dropText: string;
}

interface IState {
    dragging: boolean;
}

export class DragAndDrop extends React.PureComponent<IProps, IState> {

    private dragCounter = 0;

    constructor(props) {
        super(props);
        this.state = {
            dragging: false
        };
    }

    private onDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    private onDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer.types && e.dataTransfer.types.includes('Files')) {
            this.setState({dragging: true});
        }
    };

    private onDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;

        if (this.dragCounter === 0) {
            this.setState({dragging: false});
        }
    };

    private onDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({dragging: false});
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.onDrop(Array.from(e.dataTransfer.files));
            this.dragCounter = 0;
        }
    };

    public render() {
        return (
            <div className="dnd-outer-div"
                 onDragEnter={this.onDragIn}
                 onDragLeave={this.onDragOut}
                 onDragOver={this.onDrag}
                 onDrop={this.onDrop}>
                {this.state.dragging &&
                <div className="dnd-inner-div-1">
                    <div className="dnd-inner-div-2">
                        <p className="awsui-util-font-size-4">{this.props.dropText}</p>
                    </div>
                </div>
                }
                {this.props.children}
            </div>
        );
    }
}