import React, { useCallback } from "react";
import { FormsRendererCustomEvent } from "@amzn/wfm-axiom-forms-renderer-stencil";
import { FormsRenderer } from "@amzn/wfm-axiom-forms-renderer";
import { Header } from "@amzn/awsui-components-react";

interface FormDisplayProps {
    formTemplate: string;
    displayTitle: string;
    initialFormResponses?: FormResponses;
    onSubmit?: (submission: FormResponses) => void;
    readOnly?: boolean;
}

export interface FormResponses {
    responses: {
        [key: string]: string;
    }
}

export const FormDisplay: React.FC<FormDisplayProps> = (props: FormDisplayProps) => {
    const {formTemplate, displayTitle, initialFormResponses, onSubmit, readOnly} = props;

    const handleSubmit = useCallback((submissionEvent: FormsRendererCustomEvent<any>) => {
        onSubmit && onSubmit(submissionEvent.detail);
    }, []);

    const FormHeader = () => <>{
        formTemplate && displayTitle && <>
            <div>
                <Header variant='h1' className='form-definition-title'>
                    {displayTitle}
                </Header>
            </div>
        </>
    }</>;

    return (
        <>
            <FormHeader/>
            <FormsRenderer
                schemaString={formTemplate}
                initialState={initialFormResponses}
                onFormSubmitted={handleSubmit}
                readOnly={readOnly}
            />
        </>
    );
}
