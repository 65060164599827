import React, { useState } from "react";
import {
    FormField,
    Input,
    Toggle,
    Select,
    SelectProps,
    SpaceBetween,
    NonCancelableCustomEvent
} from "@amzn/awsui-components-react/polaris";

type TimeToEditProps = {
    timeToEditFormSubmissions: number | null | undefined;
    updateFormDefinitionField: Function;
    errorText: string;
};

const UNIT_SELECT_OPTIONS: SelectProps.Option[] = [
    { label: "Days", value: "DAY" },
    { label: "Hours", value: "HOUR" },
    { label: "Minutes", value: "MINUTE" }
];

enum MINUTE_CONVERSIONS {
    MINUTE = 1,
    HOUR = 60,
    DAY = 24 * 60
}

export const convertMinutesToTimeUnit = (minutes: number, timeUnit: string) => {
    return minutes / MINUTE_CONVERSIONS[timeUnit];
};

const convertTimeUnitToMinutes = (minutes: number, timeUnit: string) => {
    return minutes * MINUTE_CONVERSIONS[timeUnit];
};

export const determineSelectedOption = (minutes: number | null | undefined) => {
    if (!minutes || minutes % MINUTE_CONVERSIONS.DAY === 0) {
        return UNIT_SELECT_OPTIONS[0];
    }

    if (minutes % MINUTE_CONVERSIONS.HOUR === 0) {
        return UNIT_SELECT_OPTIONS[1];
    }

    return UNIT_SELECT_OPTIONS[2];
};

export const isPositiveIntegerNumber = (value: any) => {
    const parsedValue = parseFloat(value);

    return (
        !Number.isNaN(parsedValue) &&
        Number.isInteger(parsedValue) &&
        parsedValue >= 0
    );
};

const TimeToEditFormSubmissionsInput = (props: TimeToEditProps) => {
    const { timeToEditFormSubmissions, updateFormDefinitionField, errorText } = props;
    const [isEditable, setIsEditable] = useState(timeToEditFormSubmissions ? true : false);
    const [selectedOption, setSelectedOption] = useState(determineSelectedOption(timeToEditFormSubmissions));

    const handleInputChange = (event: NonCancelableCustomEvent<any>) => {
        const value = event.detail.value;

        if (isPositiveIntegerNumber(value)) {
            const valueInMinutes = convertTimeUnitToMinutes(
                parseFloat(value),
                selectedOption.value as string
            );
            updateFormDefinitionField(
                "timeToEditFormSubmissions",
                valueInMinutes
            );
        } else {
            updateFormDefinitionField("timeToEditFormSubmissions", value);
        }
    };

    const toggleIsEditable = (isOn: boolean) => {
        setIsEditable(isOn);
        if (!isOn) {
            updateFormDefinitionField("timeToEditFormSubmissions", 0);
        }
    };

    const valueString = isPositiveIntegerNumber(timeToEditFormSubmissions)
        ? `${convertMinutesToTimeUnit(
                parseFloat(`${timeToEditFormSubmissions}`),
                selectedOption.value as string,
            )}`
        : `${timeToEditFormSubmissions}`;

    return (
        <SpaceBetween size="s">
            <FormField label="Are form submissions editable?">
                <Toggle checked={isEditable}
                    onChange={({ detail }) => toggleIsEditable(detail.checked)}
                >
                    {isEditable ? "Yes" : "No"}
                </Toggle>
            </FormField>
            {isEditable && (
                <FormField label="Timeframe for submissions to be editable"
                    description="How long a user has to edit a form they submitted"
                    errorText={errorText}
                >
                    <SpaceBetween size="m" direction="horizontal">
                        <Input type="number"
                            value={`${valueString}`}
                            onChange={handleInputChange}
                        />
                        <Select selectedOption={selectedOption}
                            options={UNIT_SELECT_OPTIONS}
                            onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
                        />
                    </SpaceBetween>
                </FormField>
            )}
        </SpaceBetween>
    );
};

export default TimeToEditFormSubmissionsInput;
