import React, {
    createContext,
    PropsWithChildren,
    useState
} from "react";
import { TenantId } from "src/@types/tenants";
import { AlertModalProps } from "src/components/common/AlertModal";
import { Tenant, Tenants } from "src/utils/Tenant";

interface NotificationsContext {
    displayAlert: boolean;
    setDisplayAlert: Function;
    alertProps: AlertModalProps | {};
    setAlertProps: Function;
    showAlert: Function;
    conditionallyDisplayTenantChangeAlert: Function;
}

const initialState: NotificationsContext = {
    displayAlert: false,
    setDisplayAlert: () => {},
    alertProps: {},
    setAlertProps: Function,
    showAlert: () => {},
    conditionallyDisplayTenantChangeAlert: () => {}
};

export const NotificationsContext =
    createContext<NotificationsContext>(initialState);

export const NotificationsContextProvider = (props: PropsWithChildren<{}>) => {
    const [displayAlert, setDisplayAlert] = useState<boolean>(false);
    const [alertProps, setAlertProps] = useState({});

    const showAlert = (alertProps: AlertModalProps) => {
        setAlertProps(alertProps);
        setDisplayAlert(true);
    };

    const conditionallyDisplayTenantChangeAlert = (contentType: 'form' | 'survey',
                                       oldTenant: Tenant,
                                       newTenantId: TenantId,
                                       changeTenant: Function) => {
        if (!newTenantId) { return; }
        
        const newTenant = Tenants.getTenantById(newTenantId);
        if (oldTenant && !oldTenant.equals(newTenant)) {
            showAlert({
                title: "Tenant changed",
                type: "warning",
                text: `The tenant of the ${contentType} you're trying to view doesn't match your default tenant,
                        we have automatically changed your default tenant to: ${newTenant.toString()}`
            });

            changeTenant(newTenant);
        }
    };

    const context = {
        displayAlert,
        setDisplayAlert,
        alertProps,
        setAlertProps,
        showAlert,
        conditionallyDisplayTenantChangeAlert
    };

    return (
        <NotificationsContext.Provider value={context}>
            {props.children}
        </NotificationsContext.Provider>
    );
};
