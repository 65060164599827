import React, { useEffect, useState } from 'react';
import { CodeEditor, CodeEditorProps } from '@amzn/awsui-components-react';
import '../../../../src/components/forms/Forms.scss'

import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/webpack-resolver';

export const i18nStrings = {
    loadingState: 'Loading code editor',
    errorState: 'There was an error loading the code editor.',
    errorStateRecovery: 'Retry',

    editorGroupAriaLabel: 'Code editor',
    statusBarGroupAriaLabel: 'Status bar',

    cursorPosition: (row: number, column: number): string => `Ln ${row}, Col ${column}`,
    errorsTab: 'Errors',
    warningsTab: 'Warnings',
    preferencesButtonAriaLabel: 'Preferences',

    paneCloseButtonAriaLabel: 'Close',

    preferencesModalHeader: 'Preferences',
    preferencesModalCancel: 'Cancel',
    preferencesModalConfirm: 'Confirm',
    preferencesModalWrapLines: 'Wrap lines',
    preferencesModalTheme: 'Theme',
    preferencesModalLightThemes: 'Light themes',
    preferencesModalDarkThemes: 'Dark themes',
};

export interface CodeSnippetProps {
    data: string;
    language: CodeEditorProps.Language;
    onChange: (data: string) => void;
    editorContentHeightRem?: number;
    propLoading: boolean;
    key?: string;
}

const convertRemToPixels = (rem) => {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const CodeSnippet: React.FC<CodeSnippetProps> = (
    {
        data, language = 'json', onChange,
        editorContentHeightRem,
        propLoading, key
    }) => {
    const [isLoading, setIsLoading] = useState<boolean>(propLoading);
    const [preference, setPreference] = useState<CodeEditorProps.Preferences>();
    const [editorHeight, setEditorHeight] = useState(convertRemToPixels(editorContentHeightRem) || 600);

    useEffect(() => {
        setIsLoading(propLoading);
    }, [propLoading]);

    return (
        <CodeEditor key={key}
                    onChange={() => {}}
                    onDelayedChange={(event) => onChange(event.detail.value)}
                    onPreferencesChange={(e) => setPreference(e.detail)}
                    ace={ace}
                    value={data || ''}
                    language={language}
                    preferences={preference}
                    loading={propLoading || isLoading}
                    i18nStrings={i18nStrings}
                    editorContentHeight={editorHeight}
                    onEditorContentResize={(e) => setEditorHeight(e.detail.height)}
        />
    );
};
