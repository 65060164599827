import { AppLayout, ContentLayout, Modal } from "@amzn/awsui-components-react";
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppNavigation from "src/components/AppNavigation";
import { FormDisplay, FormResponses } from "src/components/forms/FormDisplay";
import {
    FormDefinition,
    FormDefinitionResponse
} from "src/components/forms/service/amplify/appsync";
import { FormsService } from "src/components/forms/service/Forms.service";
import { withKatalAssets } from "src/components/wrappers/withKatalAssets";
import './Forms.scss';
import {
    Alert,
    Button,
    ColumnLayout,
    Container,
    Header,
    Link,
    SpaceBetween,
    StatusIndicator,
    Textarea,
    Tabs
} from "@amzn/awsui-components-react/polaris";
import AppBreadcrumbs from "src/components/AppBreadcrumbs";
import { Loading } from "src/components/Loading";
import { DecoratedFlashbar } from "src/components/DecoratedFlashbar";
import { CodeSnippet } from "src/components/common/code-editor/CodeSnippet";
import FormHistory from "src/components/forms/FormHistory";
import BindleLink from "src/components/common/BindleLink";
import { getAbsoluteMoment } from "src/components/DateFormatter";
import PhoneToolLink from "src/components/common/PhoneToolLink";
import { convertMinutesToTimeUnit, determineSelectedOption } from 'src/components/forms/TimeToEditFormSubmissionsInput';
import { UserContext } from "../context/UserContext";
import { NotificationsContext } from 'src/components/context/NotificationsContext';
import { Tenants } from "src/utils/Tenant";
// import { TENANT_STRING_MAPPING } from "src/@types/tenants";


interface FormDetailedViewProps {
    tenantId?: string;
    language?: string;
    formName?: string;
    versionId?: string | null;
}

interface EditorContent {
    json?: any;
}

export interface FormDefinitionCache {
    [key: string]: FormDefinition[];
}

export const VERSION_ID_QUERY_PARAM_NAME = 'version-id';

export const metadataColumnConfig = [
    [
        {
            label: 'Id',
            attributeName: 'id'
        },
        {
            label: 'Form Name',
            attributeName: 'formName'
        },
        {
            label: 'Description',
            attributeName: 'description'
        },
        {
            label: 'Author',
            attributeName: 'createdBy'
        }
    ],
    [
        {
            label: 'Status',
            attributeName: 'status'
        },
        {
            label: 'Created Date',
            attributeName: 'createdDateTime'
        },
        {
            label: 'Version',
            attributeName: 'version'
        },
        {
            label: 'Language',
            attributeName: 'language'
        }
    ],
    [
        {
            label: 'Application',
            attributeName: 'application'
        },
        {
            label: 'Tenant',
            attributeName: 'tenantId'
        },
        {
            label: 'Template Type',
            attributeName: 'formTemplateType'
        },
        {
            label: 'Help Content Link',
            attributeName: 'helpContentLink'
        }
    ],
    [
        {
            label: 'Bindle',
            attributeName: 'bindleResourceId'
        },
        {
            label: 'Are form submissions editable?',
            attributeName: 'timeToEditFormSubmissions'
        }
    ]
];

export const getAttributeValue = (
    formDefinition: FormDefinition | undefined,
    attributeName: string,
) => {
    const value = formDefinition?.[attributeName];

    if (value !== 0 && !value) return "-";

    switch (attributeName) {
        case "tenantId":
            return Tenants.getTenantById(value).toString();
        case "helpContentLink":
            return <Link href={value}>{value}</Link>;
        case "createdBy":
            return <PhoneToolLink login={value} />;
        case "status":
            return <StatusIndicator type={value === "Active" ? "success" : "error"}>{value}</StatusIndicator>;
        case "createdDateTime":
            return <>{getAbsoluteMoment(value)}</>;
        case "bindleResourceId":
            return <BindleLink bindleResourceId={value}/>;
        case 'timeToEditFormSubmissions':
            const selectedUnitOption = determineSelectedOption(value);
            const timeframe = convertMinutesToTimeUnit(value, selectedUnitOption.value as string);
            return value === 0
                ? 'No'
                : <span>Yes {`(${timeframe} ${selectedUnitOption.label})`}</span>;
        default:
            return value;
    }
};

export const Content: React.FC<FormDetailedViewProps> = (props: FormDetailedViewProps) => {
    const { tenantId, language, formName, versionId } = props;
    const navigate = useNavigate();
    const formDefinitionCache = useRef<FormDefinitionCache>({});

    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<any>();
    const [infoMessage, setInfoMessage] = useState<any>();

    const [formTemplate, setFormTemplate] = useState<EditorContent>({
        json: undefined
    });
    const [formMetadata, setFormMetadata] = useState<EditorContent>({
        json: undefined
    });

    const [formDefinition, setFormDefinition] = useState<FormDefinition>();
    const [userCanEdit, setUserCanEdit] = useState(false);
    const [formSubmission, setFormSubmission] = useState<FormResponses>();

    useEffect(() => {
        getForm();
    }, [formName, versionId]);

    useEffect(() => {
        if (formMetadata && formDefinition) {
            try {
                const newFormDefinition = {
                    ...formMetadata.json,
                    formTemplate: JSON.stringify(formTemplate.json)
                };
                setFormDefinition(newFormDefinition);
            } catch (error: any) {
                setErrorMessage(error.toString());
                // tslint:disable-next-line:no-console
                console.error(error, formTemplate.json);
            }
        }
    }, [formMetadata]);

    useEffect(() => {
        if (formTemplate && formDefinition) {
            try {
                const newFormDefinition = {
                    ...formDefinition,
                    formTemplate: JSON.stringify(formTemplate.json)
                };
                setFormDefinition(newFormDefinition);
            } catch (error: any) {
                setErrorMessage(error.toString());
                // tslint:disable-next-line:no-console
                console.error(error, formTemplate.json);
            }
        }
    }, [formTemplate]);

    const onCreateNewVersion = () => {
        const destination = `/form-designer/${formDefinition!.tenantId}/${formDefinition!.language}/${formDefinition!.formName}`
                            + `${versionId ? `/${versionId}` : ''}/edit`;
        
        navigate(destination);
    };

    const getForm = async () => {

        setLoading(true);
        setErrorMessage(undefined);
        setFormDefinition(undefined);

        const promise = versionId
            ?   FormsService.getFormDefinitionById(versionId)
            :   FormsService.getLatestFormDefinition(tenantId, language, formName)

        promise
        .then(handleGetFormResponse)
        .catch(handleError);
    };

    const handleGetFormResponse = (response?: FormDefinitionResponse) => {
        const formDefinition = response?.body?.formDefinition;
        
        if (FormsService.isSuccessful(response) && formDefinition) {
            const userCanEdit = response?.body?.isEditableByUser;

            setUserCanEdit(!!userCanEdit);
            setFormDefinition(formDefinition);
            setFormTemplate({json: JSON.parse(formDefinition.formTemplate)});
            
            const newMetadata: any = {...formDefinition};
            delete newMetadata.formTemplate;
            setFormMetadata({json: newMetadata});

            setErrorMessage(undefined);
            setLoading(false);
        } else {
            handleError(`Form with name ${formName} not found`);
        }

    };

    const handleError = (responseError: any) => {
        setLoading(false);
        setInfoMessage(undefined);
        setErrorMessage(typeof responseError === 'string' ? responseError : JSON.stringify(responseError));
        // tslint:disable-next-line:no-console
        console.error(errorMessage);
    };

    const ErrorBanner = () => (
        <>{errorMessage &&
        <Alert
            dismissAriaLabel="Close"
            dismissible
            header="Error loading form"
            type="error"
        >
            {errorMessage}
        </Alert>
        }</>
    );

    const InfoBanner = () => (
        <>{infoMessage &&
        <Alert
            header="Info"
        >
            {JSON.stringify(infoMessage)}
        </Alert>
        }</>
    );

    const SubmissionDialog = () => (<>
        {formSubmission &&
        <Modal
            visible
            closeAriaLabel="Close modal"
            header="Form Submission"
            onDismiss={() => setFormSubmission(undefined)}
        >
            <InfoBanner/>
            <ErrorBanner/>
            <Loading/>
            <pre>{JSON.stringify(formSubmission, null, 2)}</pre>
        </Modal>
        }
    </>);

    const FormDesignerPreview = () => (
        <Container className='forms-designer-preview'>
            <div>
                <InfoBanner/>
                <ErrorBanner/>
                {formDefinition && <>
                    <FormDisplay formTemplate={formDefinition?.formTemplate}
                                 displayTitle={formDefinition?.displayTitle}
                                 onSubmit={() => {}}/>
                </>}
            </div>
            <SubmissionDialog/>
        </Container>
    );

    if (loading) {
        return (
            <Loading />
        )
    } else {
        return (
            <ContentLayout header={
                <Header
                    variant="h1"
                    actions={
                        userCanEdit &&
                        <Button onClick={onCreateNewVersion}>
                            Edit
                        </Button>
                    }
                >
                    {formDefinition?.formName}
                </Header>
            }>
                <SpaceBetween size="s">
                    <Container header={
                        <Header variant='h1'
                                description={'Detailed view of forms metadata and template.'}
                        >
                            Form Metadata
                        </Header>}
                        data-testid='metadata-container'
                    >
                        <ColumnLayout columns={metadataColumnConfig.length} variant="text-grid">
                            {metadataColumnConfig.map((column, index) => {
                                return (
                                    <div key={`metadata-col-${index}`}>
                                        <SpaceBetween direction="vertical" size="m">
                                            {column.map((element, subIndex) => {
                                                return (
                                                    <div key={`metadata-item-${subIndex}`}>
                                                        <div className="label">{element.label}</div>
                                                        <div>
                                                            {getAttributeValue(formDefinition, element.attributeName)}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </SpaceBetween>
                                    </div>
                                );
                            })}
                        </ColumnLayout>
                    </Container>
                    <Tabs tabs={[
                        {
                            label: "Preview",
                            id: "details-preview",
                            content: 
                                <Container header={
                                    <Header variant='h1'
                                            description={'Form template preview'}
                                    >
                                        Preview
                                    </Header>
                                }>
                                    <FormDesignerPreview/>
                                </Container>
                        },
                        {
                            label: "Template",
                            id: "details-template",
                            content: 
                                <Container header={
                                    <Header variant='h1'
                                            description={'Raw form template'}
                                    >
                                        Form Template
                                    </Header>
                                }>  
                                    <SpaceBetween size="xs">
                                        <Alert
                                            type="info"
                                        >
                                            Editing this section won't have any effect.
                                        </Alert>     
                                        <CodeSnippet
                                                onChange={(string) =>
                                                    setFormTemplate(JSON.parse(string))
                                                }
                                                data={JSON.stringify(formTemplate.json, null, 2)}
                                                language='json'
                                                editorContentHeightRem={50}
                                                propLoading={loading}
                                            />
                                    </SpaceBetween>
                                </Container>
                        },
                        {
                            label: "Extensions",
                            id: "details-extensions",
                            content:
                                <Container header={
                                    <Header variant='h1'
                                            description={'Form definition extensions'}
                                    >
                                        Extensions
                                    </Header>
                                }>
                                    {formDefinition?.extensions &&
                                    <Textarea
                                        rows={20}
                                        value={JSON.stringify(formDefinition.extensions, null, 4)}
                                        disabled
                                    />}
                                </Container>
                        },
                        {
                            label: "History",
                            id: "form-history",
                            content: 
                                formDefinition &&
                                <FormHistory
                                    tenantId={formDefinition.tenantId}
                                    formName={formDefinition.formName}
                                    language={formDefinition.language}
                                    formDefinitionCache={formDefinitionCache}
                                />
                        }
                    ]}
                    />
                </SpaceBetween>
            </ContentLayout>
        );
    }
};


export const FormDetailedView = () => {
    const { tenantId, language, formDefinitionName } = useParams();
    const { search } = useLocation();
    const { selectedTenant, setSelectedTenant } = useContext(UserContext);
    const { conditionallyDisplayTenantChangeAlert } = useContext(NotificationsContext);

    conditionallyDisplayTenantChangeAlert('form', selectedTenant, tenantId, setSelectedTenant)

    const queryParams = new URLSearchParams(search);
    const versionId = queryParams.get(VERSION_ID_QUERY_PARAM_NAME);

    // @ts-ignore
    let flash = location.state?.flash ?? [];

    return (
        <AppLayout
            breadcrumbs={<AppBreadcrumbs items={
                [
                    {text: 'Axiom Admin', href: '/'},
                    {text: 'Form Designer', href: '/form-designer'},
                    {text: `${formDefinitionName}`, href: '#/'}
                ]}/>}
            contentType={"default"}
            content={withKatalAssets(
                <Content tenantId={tenantId}
                    language={language}
                    formName={formDefinitionName}
                    versionId={versionId}
                />
            )}
            navigation={<AppNavigation/>}
            notifications={!!flash && <DecoratedFlashbar items={flash} />}
        />
    );
};
