import React from 'react';
import AppNavigation from "src/components/AppNavigation";
import AppBreadcrumbs from "src/components/AppBreadcrumbs";
import {
    AppLayout,
    Box,
    Header,
    SpaceBetween,
    Form,
    Container,
    FormField,
    Input,
    RadioGroup,
    Alert,
    Icon,
    TokenGroup
} from "@amzn/awsui-components-react/polaris";
import { ImageService } from "src/components/image-viewer/Image.service";
import { DragAndDrop } from "src/components/drag-and-drop/DragAndDrop";
import { ImageUploaderActions } from "src/components/image-viewer/ImageUploaderActions";

interface IProps {
}

interface IState {
    tenant: string;
    stage: string;
    language: string;
    path: string;
    imageName: string;
    imageError: string;
    filesToUpload: File[]
}

class Content extends React.PureComponent<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            tenant: "test",
            stage: "beta",
            language: "en-US",
            path: "",
            imageName: "",
            imageError: "",
            filesToUpload: []
        };
    }

    public componentDidMount() {
        document.title = 'Axiom Admin - Image Uploader';
    }

    private onDrop = (files: File[]) => {
        this.setState({imageError: ''}, () => {
            if (this.validateFileTypes(files)) {
                this.setState({filesToUpload: files});
            } else {
                this.setState({imageError: 'The image format must be jpeg or png'});
            }
        });
    };

    private validateFileTypes = (files) => {
        for (const file of files) {
            if (!(['image/jpeg', 'image/png'].includes(file.type))) {
                return false;
            }
        }
        return true;
    };

    private onUpload = async () => {
        const {filesToUpload} = this.state;
        const fullPath = this.generatePath();
        try {
            return await ImageService.uploadFiles(fullPath, filesToUpload);
        } catch (err) {
            // @ts-ignore
            this.setState({imageError: err.toString()})
        }
    };

    private generatePath = () => {
        const {path, tenant, language} = this.state;
        return path ? `${tenant}/help/${language}/${path}/` : `${tenant}/help/${language}/`
    };

    public render() {
        const {tenant, stage, language, path, imageError, filesToUpload} = this.state;
        return (
            <React.Fragment>
                {
                    imageError &&
                    <Alert type="error" dismissible onDismiss={() => this.setState({imageError: ''})}>
                        {imageError}
                    </Alert>
                }
                <form onSubmit={e => e.preventDefault()}>
                    <Form
                        actions={<ImageUploaderActions onUpload={this.onUpload}/>}
                        header={
                            <Header
                                variant="h1"
                                description="Upload an image for a given tenant"
                            >
                                Upload image
                            </Header>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <Container
                                header={
                                    <Header variant="h2">
                                        Image settings
                                    </Header>
                                }
                            >
                                <SpaceBetween direction="vertical" size="l">
                                    <FormField label="Select tenant">
                                        <RadioGroup
                                            onChange={({detail}) => this.setState({tenant: detail.value})}
                                            value={tenant}
                                            // TODO: extract tenant, stage, language values to a configuration file or from tenant config
                                            items={[
                                                {value: "test", label: "test"},
                                                {value: "sps", label: "sps"},
                                            ]}
                                        />
                                    </FormField>
                                    <FormField label="Select stage" description="Only beta uploads are supported">
                                        <RadioGroup
                                            onChange={({detail}) => this.setState({stage: detail.value})}
                                            value={stage}
                                            items={[
                                                {value: "beta", label: "beta"},
                                                {value: "prod", label: "prod", disabled: true},
                                            ]}
                                        />
                                    </FormField>
                                    <FormField label="Select language">
                                        <RadioGroup
                                            onChange={({detail}) => this.setState({language: detail.value})}
                                            value={language}
                                            items={[
                                                {value: "en-US", label: "en-US"}
                                            ]}
                                        />
                                    </FormField>
                                </SpaceBetween>
                            </Container>
                            <Container
                                header={
                                    <Header variant="h2">
                                        Path settings
                                    </Header>
                                }
                            >
                                <SpaceBetween direction="vertical" size="l">
                                    <FormField label="Path to content"
                                               description="Do not add tenant, stage or language">
                                        <Input value={path}
                                               onChange={({detail}) =>
                                                   this.setState({path: detail.value})}
                                        />
                                    </FormField>
                                    <FormField label="Resulting path">
                                        <Input value={this.generatePath()} disabled/>
                                    </FormField>
                                </SpaceBetween>
                            </Container>
                            <Container
                                header={
                                    <Header variant="h2">
                                        File drop
                                    </Header>
                                }
                            >
                                <DragAndDrop
                                    onDrop={this.onDrop}
                                    dropText={""}>
                                    <SpaceBetween direction="vertical" size="l">
                                        <Box margin="xxl" padding="xxl" textAlign="center" variant="p"
                                             color="text-body-secondary" fontSize="heading-s">
                                            <Icon size="normal" variant="disabled" name="upload"/> Drop images here
                                        </Box>
                                    </SpaceBetween>
                                </DragAndDrop>
                                <TokenGroup
                                    onDismiss={({detail: {itemIndex}}) => {
                                        this.setState({
                                            filesToUpload: [
                                                ...filesToUpload.slice(0, itemIndex),
                                                ...filesToUpload.slice(itemIndex + 1)
                                            ]
                                        });
                                    }}
                                    items={filesToUpload.map((file) => ({
                                        label: file.name
                                    }))}
                                />
                            </Container>
                        </SpaceBetween>
                    </Form>
                </form>
            </React.Fragment>
        );
    }
}

export const ImageUploader = () => {
    return (

        <AppLayout
            breadcrumbs={<AppBreadcrumbs items={
                [
                    {text: 'Axiom Admin', href: '/'},
                    {text: 'Image Viewer', href: '/image-viewer'},
                    {text: 'Upload', href: '#/'}
                ]}/>}
            contentType="form"
            content={<Content/>}
            navigation={<AppNavigation/>}
        />
    );
};