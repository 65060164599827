import { useState, useEffect } from "react";
import { FormsService } from "../components/forms/service/Forms.service";
import { FormDefinitionCache } from "../components/forms/FormDetailedView";
import {
    FormDefinition,
    FormDefinitionsResponse
} from "src/components/forms/service/amplify/appsync";

const useFormDefinitionsByFormName = (
    tenantId: string,
    formName: string,
    language: string,
    cache: React.MutableRefObject<FormDefinitionCache>
) => {
    const [formDefinitions, setFormDefinitions] = useState<FormDefinition[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (cache.current[formName]) {
            setFormDefinitions(cache.current[formName]);
            setLoading(false);
        } else {
            FormsService.getFormDefinitionsByFormName(tenantId, formName, language)
                .then((res: FormDefinitionsResponse) => {
                    if (FormsService.isSuccessful(res)) {
                        const formDefinitions = res.body as FormDefinition[];
                        cache.current[formName] = formDefinitions;
                        
                        setFormDefinitions(formDefinitions);
                        setLoading(false);
                    } else {
                        throw new Error(
                            `Unable to fetch the form definitions for tenantId ${tenantId}, `+
                            `formName ${formName} and language ${language}: ${res}`
                        );
                    }
                })
                .catch((err: any) => {
                    setError(err);
                    setLoading(false);
                });
        }
    }, [tenantId, formName, language]);

    return { formDefinitions, loading, error };
};

export { useFormDefinitionsByFormName };
