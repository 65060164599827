import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { getUserInfo, setupAWSConfigForService } from "src/aws-config";
import { AmplifyConfigType } from "src/components/wrappers/ServiceWrapperFunction";

export class AmplifyAuthenticationService {

    public static async authenticate(service: AmplifyConfigType): Promise<any> {
        await setupAWSConfigForService(service, true);
        const amplifyCredentials = await AmplifyAuthenticationService.getAmplifyCredInfo();
        console.log("Amplify Credentials" + `-${service}`, amplifyCredentials);
        if (amplifyCredentials) {
            return await getUserInfo();
        }
    }

    private static async getAmplifyCredInfo() {
        let amplifyCredInfo: any = null;
        try {
            try {
                const user: CognitoUser = await Auth.currentAuthenticatedUser();
                amplifyCredInfo = user.getSignInUserSession()!.getIdToken().decodePayload();
            } catch (e) {
                // tslint:disable-next-line:no-console
                console.log(e);
            }
            // We should trigger Federate redirects only if we haven't redirected already
            if (!amplifyCredInfo) {
                await AmplifyAuthenticationService.attemptAmplifyFederateSignIn();
            }
            // tslint:disable-next-line:no-console
            console.log(`Amplify User Auth Info ${amplifyCredInfo ? '' : 'not'} available`, amplifyCredInfo);

        } catch (e) {
            // Amplify auth should hold in all errors
            // tslint:disable-next-line:no-console
            console.log(e);
        }
        return amplifyCredInfo;
    }

    public static async signOut() {
        try {
            const result = await Auth.signOut();
            console.log("Signed out successfully", result);
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.log("Error signing out", e);
        }
    }

    private static async attemptAmplifyFederateSignIn() {
        // tslint:disable-next-line:no-console
        console.log("Initiating Federate Amplify authentication");
        await Auth.federatedSignIn({customProvider: 'FederateOIDC'});
    }
}