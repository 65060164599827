import React, { useEffect, useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { Box, CollectionPreferences, Pagination, RadioGroup, Table, TextFilter } from "@amzn/awsui-components-react";
import { Button, Header, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import { useNavigate } from "react-router-dom";
import "./ResourcesTable.scss";

interface IProps {
    columnDefinitions: any[];
    items: any[];
    loading: boolean;
    loadingText: string;
}

const PAGE_SIZE_OPTIONS = [
    { value: 10, label: "10 questions" },
    { value: 20, label: "20 questions" },
    { value: 30, label: "30 questions" }
];

const Preferences = ({ preferences, setPreferences }) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        pageSizePreference={{
            title: "Page size",
            options: PAGE_SIZE_OPTIONS
        }}
    />
);

const TableHeader = (props) => {
    return (
        <Header variant={props.variant} actions={props.actionButtons}>
            {props.title}
        </Header>
    );
};

const FullPageHeader = ({ resourceName = "Survey Builder", ...props }) => {
    let navigate = useNavigate();

    const onCreateQuestion = async () => {
        navigate("/survey-builder/create");
    };

    return (
        <div>
            <SpaceBetween size="s" direction="vertical">
                <TableHeader
                    variant="awsui-h1-sticky"
                    title={resourceName}
                    actionButtons={
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button variant="primary" data-testid="create-question-button" onClick={onCreateQuestion}>
                                Create question
                            </Button>
                        </SpaceBetween>
                    }
                    {...props}
                />
            </SpaceBetween>
        </div>
    );
};

function EmptyState({ title, subtitle }) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: "s" }} color="inherit">
                {subtitle}
            </Box>
        </Box>
    );
}

export default function ResourcesTable(props: IProps) {
    const [preferences, setPreferences] = useState({
        pageSize: 20,
        visibleContent: ["id", "question", "survey_status", "author_id", "start_date", "end_date"],
        wrapLines: false
    });

    const { items, collectionProps, filterProps, paginationProps } = useCollection(props.items, {
        filtering: {
            empty: <EmptyState title="No questions" subtitle="No questions to display" />,
            noMatch: <EmptyState title="No matches" subtitle="Can't find a match" />
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {}
    });

    const { selectedItems } = collectionProps;

    return (
        <Table
            {...collectionProps}
            selectionType="multi"
            header={
                <FullPageHeader
                    selectedItems={selectedItems}
                    totalItems={items}
                >
                    Survey Builder
                </FullPageHeader>
            }
            loading={props.loading}
            loadingText={props.loadingText}
            columnDefinitions={props.columnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            pagination={<Pagination {...paginationProps} />}
            filter={<TextFilter {...filterProps} filteringPlaceholder="Find questions" />}
            preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
        />
    );
}
