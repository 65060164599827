import { domains, themes } from 'katal-platform-versions';
import React from "react";

const loadKatalAssets = () => {
    const scriptElement = document.createElement('script');

    // @ts-ignore
    scriptElement.src = domains.prod + themes.flo.stableWithDataGrid;

    scriptElement.crossOrigin = 'anonymous';

    scriptElement.onload = () => {
        console.log(`Katal Assets loaded via ${scriptElement.src}`);
    };
    scriptElement.onerror = () => {
        console.log('Error loading Katal Assets');
    };

    // @ts-ignore
    document.querySelector("head").appendChild(scriptElement);
};

const KatalAssetLoader = props => {
    React.useEffect(() => loadKatalAssets(), []);
    // Render whatever is passed between the tags
    // '<KatalAssetLoader>...</KatalAssetLoader>' with katal assets loaded
    return <div className={'katal'}>{props.children}</div>;
};

/**
 * This component is used to load Katal.
 * Since AxiomAdmin Project is built with Polaris components,
 * loading Katal assets is not necessary for the whole project.
 * This component is used to load Katal assets only for the components that are using Katal components.
 *
 * @param component Component that is using Katal styles and components.
 */
export const withKatalAssets = (component: JSX.Element) => (
    <KatalAssetLoader>{component}</KatalAssetLoader>
);