import React, { useEffect, useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { AlertProps } from "@amzn/awsui-components-react/polaris/alert";
import { NotificationsContext } from "src/components/context/NotificationsContext";

export interface AlertModalProps {
    title: string;
    text: string;
    type: AlertProps.Type;
    onExit?: Function;
    onDismiss?: Function;
    onOk?: Function;
    onOpen?: Function;
}

const AlertModal = () => {
    const { displayAlert, setDisplayAlert, alertProps } = useContext(NotificationsContext);
    const { title, text, type, onDismiss, onOk, onOpen } = alertProps as AlertModalProps;

    useEffect(() => {
        if (onOpen) {
            onOpen();
        }
    }, [alertProps]);

    const onDismissHandler: any = (event) => {
        if (onDismiss) {
            onDismiss(event);
        }
        setDisplayAlert(false);
    };

    const onOkHandler: any = (event) => {
        if (onOk) {
            onOk(event);
        }
        setDisplayAlert(false);
    };

    return (
        <Modal
            onDismiss={onDismissHandler}
            visible={displayAlert}
            header={title}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        {onDismiss && (
                            <Button variant="link" onClick={onDismissHandler}>
                                Cancel
                            </Button>
                        )}
                        <Button variant="primary" onClick={onOkHandler}>
                            Ok
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Alert type={type} statusIconAriaLabel={type}>
                {text}
            </Alert>
        </Modal>
    );
};

export default AlertModal;
